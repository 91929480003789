export default {
  //Posizione di default (Ponte di Rialto)
  defaultCoords: {
    lat: 45.43926181649223,
    lng: 12.335729864620374,
  },
  limits: {
    minLat: 45.422991,
    maxLat: 45.502159, //45.450815
    minLng: 12.300728,
    maxLng: 12.367236,
  },
  bounds: {
    southwest: [12.243241, 45.414381],
    northeast: [12.414473, 45.467512],
  },
};
