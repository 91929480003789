import { createAction } from '@reduxjs/toolkit';
import { Address, Order, Store, User } from 'interfaces';
import { FoodOrdersDeliveryMethod } from 'types/orders/foodOrdersDeliveryMethod';
import { OrderPaymentMethodEnum } from 'types/orders/orderPaymentMethodEnum';

export const init = createAction('order/init');
export const setstore = createAction<Store>('order/setstore');
export const removestore = createAction('order/removestore');
export const setuser = createAction<User>('order/setuser');
export const setcart = createAction<string>('order/setcart');
export const setpaymentmethod = createAction<OrderPaymentMethodEnum>(
  'order/setpaymentmethod',
);
export const setdeliverymethod = createAction<FoodOrdersDeliveryMethod>(
  'order/setdeliverymethod',
);
export const setdeliveryaddress = createAction<Address>(
  'order/setdeliveryaddress',
);
export const setdeliveryprice = createAction<{
  total: number;
  delivery_price: number;
  walking_time: number;
  extra_fee?: number;
  extra_anonymous?: number;
  extra_rain?: number;
  extraPiano?: number;
}>('order/setdeliveryprice');
export const setdeliverydate = createAction<{
  date: any;
  delivery_time: any;
  real_delivery_time: any;
  real_pickup_time: any;
}>('order/setdeliverydate');
export const addGhostNote = createAction<string>('order/addGhostNote');
export const addorderdata = createAction<Order>('order/addorderdata');
