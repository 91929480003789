import { createReducer } from '@reduxjs/toolkit';
import { Cart, CartItem } from 'interfaces';
import { CartOriginEnum } from 'types/cartOrigin';
import {
  addproduct,
  decreasequantity,
  increasequantity,
  init,
  removeproduct,
} from './cart.actions';

const cartInitialState: Cart = {
  cartTotal: 0,
  items: [],
  totalQuantity: 0,
  version: 1,
  provenienza: window.ReactNativeWebView
    ? CartOriginEnum.App
    : CartOriginEnum.WebApp,
  debug: {
    uagent: navigator.userAgent,
  },
};

const calcProdTotal = (item: CartItem): number => {
  let price = item.unitPrice;
  if (item.addons && item.addons.length > 0) {
    item.addons.forEach(addon => {
      price = price + addon.price;
    });
  }
  if (item.menus && item.menus.length > 0) {
    item.menus.forEach(menu => {
      price = price + menu.price;
    });
  }
  if (item.productIngredients && item.productIngredients.length > 0) {
    item.productIngredients.forEach(prodIng => {
      price = price + prodIng.price;
    });
  }
  return price * item.quantity;
};

const calcTotal = (items: CartItem[]): number => {
  let total = 0;
  if (items && items.length > 0) {
    items.forEach(item => {
      total = total + calcProdTotal(item);
    });
  }
  return total;
};

export const cartReducer = createReducer(cartInitialState, builder =>
  builder
    .addCase(init, () => cartInitialState)
    .addCase(addproduct, (state, action) => {
      if (action.payload) {
        const id_item = `${action.payload.id}_${Math.random()
          .toString(36)
          .slice(-8)}`;
        const items = [...state.items, { ...action.payload, id_item }];
        const cartTotal = calcTotal(items);
        return {
          ...state,
          items,
          cartTotal,
          totalQuantity: state.totalQuantity + 1,
        };
      }
      return state;
    })
    .addCase(removeproduct, (state, action) => {
      if (action.payload.id) {
        const products = state.items.filter(
          item => item.id === action.payload.id,
        );
        if (products) {
          if (products.length === 1) {
            const items = state.items.filter(
              item => item.id !== action.payload.id,
            );
            const cartTotal = calcTotal(items);
            return {
              ...state,
              items,
              cartTotal,
              totalQuantity: state.totalQuantity - 1,
            };
          } else if (products.length > 1) {
            const product = products.find(
              item => item.id_item === action.payload.id_item,
            );
            if (product) {
              const items = state.items.filter(
                item =>
                  item.id !== product.id && item.id_item !== product.id_item,
              );
              const cartTotal = calcTotal(items);
              return {
                ...state,
                items,
                cartTotal,
                totalQuantity: state.totalQuantity - 1,
              };
            }
          }
        }
      }
      return state;
    })
    .addCase(increasequantity, (state, action) => {
      if (action.payload.id) {
        const product = state.items.find(
          item =>
            item.id === action.payload.id &&
            item.id_item === action.payload.id_item,
        );
        if (product) {
          const quantity = product.quantity + 1;
          const totalPrice = calcProdTotal({ ...product, quantity });
          const items = state.items.map(item =>
            item.id === product.id && item.id_item === product.id_item
              ? { ...product, totalPrice, quantity }
              : item,
          );
          const cartTotal = calcTotal(items);
          return {
            ...state,
            items,
            cartTotal,
          };
        }
      }
      return state;
    })
    .addCase(decreasequantity, (state, action) => {
      if (action.payload.id) {
        const product = state.items.find(
          item =>
            item.id === action.payload.id &&
            item.id_item === action.payload.id_item,
        );
        if (product) {
          if (product.quantity > 1) {
            const totalPrice =
              (product.totalPrice / product.quantity) * (product.quantity - 1);
            const quantity = product.quantity - 1;
            const items = state.items.map(item =>
              item.id === product.id && item.id_item === product.id_item
                ? { ...product, totalPrice, quantity }
                : item,
            );
            const cartTotal = calcTotal(items);
            return {
              ...state,
              items,
              cartTotal,
            };
          } else {
            const items = state.items.filter(
              item => item.id_item !== product.id_item,
            );
            const cartTotal = calcTotal(items);
            return {
              ...state,
              items,
              totalQuantity: state.totalQuantity - 1,
              cartTotal,
            };
          }
        }
      }
      return state;
    }),
);
