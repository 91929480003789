import React, { useEffect, useState } from 'react';
import { NavBar } from '../../../components/navBar';
import navBarIcons from '../../../constants/navBarIcons';
import {
  useGetIdentity,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import { Button } from '@pankod/refine-antd';
import './styles.css';
import icons from '../../../constants/icons';
import { Loading } from '../../loading';
import axios from 'axios';

import UserHandler from '../../../util/UserHandler';

function Wallet() {
  const { data: user } = useGetIdentity();
  const [userData, setUserData] = useState();
  const { push, goBack } = useNavigation();
  const t = useTranslate();

  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    try {
      console.log(
        'UserHandler.getInstance().token',
        UserHandler.getInstance().token,
      );

      await axios
        .get(
          `${process.env.REACT_APP_API}/user/${user.id}`,

          {
            // params: {
            //   user: {
            //     id: user.id,
            //     role: user.role,
            //   },
            // },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${UserHandler.getInstance().token}`,
            },
          },
        )
        .then(res => {
          setUserData(res.data.data);
          console.log('res', res.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user) {
      push('/login');
    }
  }, [user]);

  if (!user) {
    return <Loading />;
  }

  return (
    <div className="profile">
      <div className="profile__navbar">
        <NavBar back={true} onBack={() => goBack()} />
      </div>
      <div className="profile__mainHeader">
        <img src={navBarIcons.avatar} />
      </div>
      <div className="profile__mainContainer">
        <div className="profile__mainContainer__header">
          <h1>Cocai wallet</h1>
          <p>{userData?.balance?.toFixed(2) ?? 0} €</p>
        </div>
        <div>
          <div className="wallet-balance-and-button">
            <span className="wallet-balance-text">
              {t('Payment_methods_page.balance')} :
              <span className="wallet-balance-amount">
                {' '}
                {userData?.balance?.toFixed(2) ?? 0} €
              </span>
            </span>
            <div>
              <Button
                type="primary"
                size="large"
                disabled={true}
                onClick={() => console.log('d')}
                block>
                richiedi saldo
              </Button>
            </div>
          </div>
          {/* 
          <div className="wallet-loyality-program-container">
            <div>Loyalty program</div>
            <div className="wallet-loyality-program-link">
              Condividi il Tuo Link Referral http://localhost:3000/webapp/wallet
            </div>
            <div>
              {' '}
              <img
                style={{ maxHeight: '40px' }}
                src={icons.profile.wallet}
                alt={'icon'}
              />
            </div>
            <div>
              {' '}
              <Button
                style={{ backgroundColor: 'grey' }}
                type="primary"
                size="large"
                disabled={false}
                onClick={() => console.log('d')}
                block>
                storico dei Referral
              </Button>
            </div>
            <div>
              {' '}
              <Button
                type="primary"
                size="large"
                disabled={false}
                onClick={() => console.log('d')}
                block>
                richiedi QR fisici
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Wallet;
