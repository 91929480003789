import { createReducer } from '@reduxjs/toolkit';
import { Closure, Opening } from 'interfaces';
import { popStore, pushstore } from './store.actions';

export interface StoreRedux {
  id: number;
  business_name: string;
  address: string;
  civic_number: string;
  district: string;
  city_code: string;
  latitude: number;
  longitude: number;
  min_order: number;
  avg_preparing_time: number;
  reaction_time: number;
  max_orders: number;
  delivery_area: number;
  credit_card: boolean;
  cash: boolean;
  delivery: boolean;
  pickup: boolean;
  opening_times?: Opening[];
  store_closures?: Closure[];
  subcategory: any;
  category: any;
}

const storeInitialState: StoreRedux = {
  id: 0,
  business_name: '',
  address: '',
  civic_number: '',
  district: '',
  city_code: '',
  latitude: 0,
  longitude: 0,
  min_order: 0,
  avg_preparing_time: 0,
  reaction_time: 0,
  max_orders: 0,
  delivery_area: 0,
  credit_card: false,
  cash: false,
  delivery: false,
  pickup: false,
  subcategory: '',
  category: '',
};

export const storeReducer = createReducer(storeInitialState, builder =>
  builder
    .addCase(popStore, () => storeInitialState)
    .addCase(pushstore, (state, action) => {
      if (action.payload) {
        return {
          ...action.payload,
        };
      }
      return state;
    }),
);
