import React, { useState } from 'react';

import './styles.css';

interface IBigSwitcher {
  leftLabel: string;
  showAdditionalInfo?: boolean; // these two added for store/products
  additionalInfo?: string;
  rightLabel: string;
  onChange: (res: boolean) => void;
  value?: boolean;
  disabled?: boolean;
}

export const BigSwitcher: React.FC<IBigSwitcher> = ({
  leftLabel,
  showAdditionalInfo,
  additionalInfo,
  rightLabel,
  onChange,
  value = true,
  disabled,
}) => {
  const [selected, setSelected] = useState(value);
  return (
    <div className={`bigswitcher ${disabled ? 'bigswitcher--disabled' : ''}`}>
      <div className="bigswitcher__content">
        <div
          className={`bigswitcher__content__left flex justify-center items-center ${
            selected ? 'bigswitcher__content--selected' : ''
          } ${showAdditionalInfo ? 'bigswitcher__content--additional' : ''}`}
          onClick={() => {
            setSelected(true);
            onChange(true);
          }}>
          <span
            style={{
              fontFamily: 'Zing Rust,Gothic, sans-serif',
            }}>
            {' '}
            {leftLabel}
          </span>

          {showAdditionalInfo ? (
            <div className="bigswitcher__content__additional">
              <span
                className={`bigswitcher__content__dot flex justify-center items-center ${
                  !selected ? 'bigswitcher__content__dot--inverted' : ''
                }`}>
                &nbsp;
              </span>
              <span className="bigswitcher__content__additionalinfo">
                {additionalInfo}
              </span>
            </div>
          ) : null}
        </div>
        <div
          className={`bigswitcher__content__right flex justify-center items-center ${
            !selected ? 'bigswitcher__content--selected' : ''
          }`}
          onClick={() => {
            setSelected(false);
            onChange(false);
          }}>
          <span style={{ fontFamily: 'Zing Rust,Gothic, sans-serif' }}>
            {' '}
            {rightLabel}
          </span>
        </div>
      </div>
    </div>
  );
};
