import { createReducer } from '@reduxjs/toolkit';
import { FoodOrdersDeliveryMethod } from 'types/orders/foodOrdersDeliveryMethod';
import { FoodOrder, OrderWithRelations } from '../../interfaces';
import { OrderPaymentMethodEnum } from '../../types/orders/orderPaymentMethodEnum';
import {
  addorderdata,
  init,
  removestore,
  setcart,
  setdeliveryaddress,
  setpaymentmethod,
  setdeliverydate,
  setdeliverymethod,
  setdeliveryprice,
  setstore,
  setuser,
  addGhostNote,
} from './order.actions';

const orderInitialState: OrderWithRelations = {
  id: 0,
  store_id: 0,
  user_id: 0,
  date: '',
  payment_method: OrderPaymentMethodEnum.CreditCard,
  total: 0,
  custom_discount: 0,
  note: null,
  number: '',
  stripe_payment_id: '',
  food_orders: {
    order_id: null,
    commission: 0,
    preparing_time: 0,
    shopping_cart: '',
    delivery_time: '',
    real_delivery_time: '',
    real_pickup_time: '',
    reaction_time: 0,
    delivery_address: '',
    latitude: 0,
    longitude: 0,
    itinerary: '',
    walking_time: 0,
    extra_fee: 0,
    extra_anonymous: 0,
    extra_rain: 0,
    extraPiano: 0,
    coupon: null,
    delivery_price: 0,
    delivery_method: FoodOrdersDeliveryMethod.DeliveryToDomicile,
  } as FoodOrder,
  // smart_logistics_orders: null,
  table_orders: null,
  users: null,
  status: null,
  store: null,
  smart_logistics_orders: null,
  feedbacks: null,
  created_by: '',
  updated_by: '',
  created_at: null,
  updated_at: null,
  deleted_at: null,
};

export const orderReducer = createReducer(orderInitialState, builder =>
  builder
    .addCase(init, () => {
      return orderInitialState;
    })
    .addCase(setstore, (state, action) => {
      if (action.payload) {
        const store = action.payload;
        const food_orders = {
          ...state.food_orders,
          preparing_time: store.avg_preparing_time,
          reaction_time: store.reaction_time,
        } as FoodOrder;
        return {
          ...state,
          store_id: action.payload.id,
          food_orders,
        };
      }
      return state;
    })
    .addCase(removestore, state => {
      const food_orders = {
        ...state.food_orders,
        preparing_time: 0,
        reaction_time: 0,
      } as FoodOrder;
      return {
        ...state,
        store_id: 0,
        food_orders,
      };
    })
    .addCase(setuser, (state, action) => {
      if (action.payload) {
        const user = action.payload;
        return {
          ...state,
          user_id: user.id,
          users: user,
        };
      }
      return state;
    })
    .addCase(setcart, (state, action) => {
      console.log('action.payload', action.payload);

      if (action.payload) {
        const food_orders = {
          ...state.food_orders,
          shopping_cart: action.payload,
        } as FoodOrder;
        return {
          ...state,
          food_orders,
        };
      }
      return state;
    })
    .addCase(setpaymentmethod, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          payment_method: action.payload,
        };
      }
      return state;
    })
    .addCase(setdeliverymethod, (state, action) => {
      if (action.payload) {
        const food_orders = {
          ...state.food_orders,
          delivery_method: action.payload,
        } as FoodOrder;
        return {
          ...state,
          food_orders,
        };
      }
      return state;
    })
    .addCase(setdeliveryaddress, (state, action) => {
      if (action.payload) {
        let runnerNotes = '';
        let note = '';
        if (
          state.note &&
          state.note.trim().length > 0 &&
          state.note?.trim().indexOf('\n\r')
        ) {
          runnerNotes = state.note?.trim().split('\n\r')[0];
          note = state.note?.trim().split('\n\r')[1];
        }
        note =
          action.payload.extra_info &&
          action.payload.extra_info.trim().length > 0
            ? action.payload.extra_info
            : note;
        const food_orders = {
          ...state.food_orders,
          delivery_address: action.payload.address,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        } as FoodOrder;
        return {
          ...state,
          note: `${runnerNotes}\n\r${note}`,
          food_orders,
        };
      }
      return state;
    })
    .addCase(setdeliveryprice, (state, action) => {
      if (action.payload) {
        const food_orders = {
          ...state.food_orders,
          delivery_price: action.payload.delivery_price,
          walking_time: action.payload.walking_time,
          extra_fee: action.payload.extra_fee,
          extra_anonymous: action.payload.extra_anonymous,
          extra_rain: action.payload.extra_rain,
          extra_piano: action.payload.extraPiano,
        } as FoodOrder;
        return {
          ...state,
          total: action.payload.total,
          food_orders,
        };
      }
      return state;
    })
    .addCase(setdeliverydate, (state, action) => {
      if (action.payload) {
        const food_orders = {
          ...state.food_orders,
          delivery_time: action.payload.delivery_time,
          real_delivery_time: action.payload.real_delivery_time,
          real_pickup_time: action.payload.real_pickup_time,
        } as FoodOrder;
        return {
          ...state,
          date: action.payload.date,
          food_orders,
        };
      }
      return state;
    })
    .addCase(addGhostNote, (state, action) => {
      const note = state.note
        ? `${action.payload.trim()}\n\r${state.note}`
        : action.payload.trim();
      return {
        ...state,
        note,
      };
    })
    .addCase(addorderdata, (state, action) => {
      if (action.payload) {
        return { ...state, ...action.payload };
      }
      return state;
    }),
);
