import { createReducer } from '@reduxjs/toolkit';
import { popNotifications, pushNotifications } from './notifications.actions';

const notificationsInitialState: string[] = [];

export const notificationsReducer = createReducer(
  notificationsInitialState,
  builder =>
    builder
      .addCase(popNotifications, () => notificationsInitialState)
      .addCase(pushNotifications, (state, action) => [
        ...state,
        action.payload,
      ]),
);
