import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { NotificationType } from '../../components/mapBox';
import { notification } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

function Support() {
  const [loading, setLoading] = useState(false);
  const t = useTranslate();
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: '',
  });

  const openNotificationWithIcon = (type: NotificationType, msg: string) => {
    notification[type]({
      message: t('payment.notifyTitle'),
      description: msg,
    });
  };

  const handleInputChange = e => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();

    // Example API endpoint where the email will be sent
    const apiUrl = `  ${process.env.REACT_APP_API}/support`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setLoading(false);
        setFormData({
          email: '',
          subject: '',
          message: '',
        });
        setSent(true);
        openNotificationWithIcon('success', 'email sent successfully');

        // Success logic here, maybe show a success message
      } else {
        setLoading(false);
        // Error logic here
        openNotificationWithIcon('error', 'Failed to send message try again');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error sending email:', error);
      openNotificationWithIcon('error', error);
    }
  };

  return (
    <div className="pb-[400px]">
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <section className="">
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2
              className={`mb-4 text-4xl tracking-tight font-extrabold text-center ${
                sent ? 'text-[#86efac]' : 'text-[white]'
              } dark:text-white"`}>
              {sent ? 'Sent' : 'Contact Us'}
            </h2>
            <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
              {sent
                ? `Your request has been sent`
                : `Have a question about your delivery? Need help with tracking your package? Want to give feedback on our service? Let us know how we can assist you.`}
            </p>
            <form onSubmit={handleSubmit} className="space-y-8">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                  Your message
                </label>
                <textarea
                  id="message"
                  rows={6}
                  value={formData.message}
                  onChange={handleInputChange}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave a comment..."></textarea>
              </div>
              <button
                type="submit"
                disabled={sent}
                className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg ${
                  sent
                    ? 'bg-[grey] sm:w-fit hover:bg-[grey]'
                    : 'bg-[#fab627] sm:w-fit hover:bg-[#ffca2c]'
                } focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                  sent ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}>
                Send message
              </button>
            </form>
            <div className="flex justify-center space-x-4 mt-8">
              <a
                href="https://www.linkedin.com/company/cocaiexpress/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
                className="text-gray-500 hover:text-[#1DA1F2]">
                <TwitterIcon fontSize="large" />
              </a>
              <a
                href="https://www.linkedin.com/company/cocaiexpress/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="text-gray-500 hover:text-[#0A66C2]">
                <LinkedInIcon fontSize="large" />
              </a>
              {/* <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                className="text-gray-500 hover:text-[#1877F2]">
                <FacebookIcon fontSize="large" />
              </a> */}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Support;
