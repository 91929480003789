import { createReducer } from '@reduxjs/toolkit';
import { popOneTime, pushOneTime } from './oneTime.actions';

const oneTimeInitialState = null;

export const oneTimeReducer = createReducer(oneTimeInitialState, builder =>
  builder
    .addCase(popOneTime, () => oneTimeInitialState)
    .addCase(pushOneTime, (_state, action) => action.payload),
);
