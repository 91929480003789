import { UserRoleEnum } from '../types/users/userRole';
import UserHandler from 'util/UserHandler';

type CanParams = {
  resource: string;
  action: string;
  params?: any;
};

type CanReturnType = {
  can: boolean;
  reason?: string;
};

const unauthorized = () =>
  Promise.resolve({
    can: false,
    reason: 'Unauthorized',
  });

const authorized = () => Promise.resolve({ can: true });

const accessControlProvider = {
  can: async ({
    resource,
    action,
    params,
  }: CanParams): Promise<CanReturnType> => {
    const user = UserHandler.getInstance().user;
    if (!user || user.role === UserRoleEnum.User) {
      return authorized();
    }
    return unauthorized();
  },
};

export default accessControlProvider;
