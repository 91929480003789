import React, { useEffect, useRef, useState } from 'react';
import OtpInput from 'react-otp-input';
import './otpStyles.css';
import { useNavigation, useTranslate } from '@pankod/refine-core';

function OTP_VERIFY(props) {
  const t = useTranslate();
  const { push } = useNavigation();
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [warn, setWarn] = useState('');

  const handleClick = async () => {
    setIsLoading(true);
    if (props.phone) {
      const res = await props.verifyOtpPhone();
      console.log('res', res);

      if (res) {
        setIsSuccess(true);
        setIsLoading(false);
      } else {
        setIsFailure(true);
        setIsLoading(false);
      }
    } else {
      await verifyOTP(props.num, otp);
    }
  };

  // Function to verify OTP
  async function verifyOTP(num, code) {
    setWarn('');
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/user/verify/otp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ num: num, otp: code }),
        },
      );

      const data = await response.json();

      if (data.error) {
        setTimeout(() => {
          setIsFailure(true);
        }, 2000);
      } else if (data.message) {
        setTimeout(() => {
          setIsSuccess(true);
          setTimeout(() => {
            props.getorder();
          }, 500);
        }, 2000);
      } else if (data.warning) {
        setWarn(data.warning);
        setTimeout(() => {
          setIsWarning(true);
        }, 2000);
      }
    } catch (error) {
      setIsFailure(true);

      console.error('Error:', error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  useEffect(() => {
    if (isFailure || isWarning) {
      setTimeout(() => {
        setIsFailure(false);
        setIsWarning(false);
      }, 2000);
    }
  }, [isSuccess, isFailure, isWarning]);

  useEffect(() => {
    if (otp && props.seTheCode) {
      props.seTheCode(otp);
    }
  }, [otp]);

  return (
    <div>
      <div style={props.style} className="form-card">
        {props.phone
          ? t('track_order.confirm_phone')
          : t('track_order.confirm_email')}
        <p className="form-card-title"></p>
        <span className="form-card-prompt">
          {t('track_order.six_digits')}{' '}
          {props.phone ? t('track_order.phone') : 'email'}
        </span>

        {props.email}

        <div className="otp-input-container">
          <OtpInput
            inputStyle={{
              borderRight: 'none',
              borderLeft: 'none',
              borderTop: 'none',
              borderBottom: '2px solid black',
              margin: '8px',
              color: 'black',
              fontSize: '20px',
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={p => <input {...p} />}
          />
        </div>
        <button
          disabled={otp.length < 6}
          className={`loading-button mb-4 ${isLoading ? 'loading' : ''} ${
            isSuccess ? 'success' : ''
          } ${isWarning ? 'warning' : ''} ${isFailure ? 'falure' : ''} ${
            otp.length < 6 ? 'disable' : ''
          }`}
          onClick={handleClick}>
          <span>Verify</span>
        </button>
        {warn && warn}
        <p className="call-again">
          {props.resend}
          {props.timeLeft === 0
            ? t('track_order.click_resend')
            : 'in ' + props.timeLeft + ' seconds'}
        </p>
        {/* <span>{t('track_order.validity')}</span> */}
      </div>
    </div>
  );
}

export default OTP_VERIFY;
