export enum OtherFiltersEnum {
  Park = 100,
  Fountain,
  Gondolier,
  Museum,
  WC,
  Pickup,
  Ferry,
  Church,
  PublicPlace,
  Favourite,
  BestsVoted,
  Offer,
  Nearby,

  smart_logistic,
  tableBooking,
  onDemand,
  Botteghe,
  lightCatering,
  bevande,
  cibo,
}
