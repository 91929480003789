import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { cartReducer } from './cart/cart.reducer';
import { deliveryReducer } from './delivery/delivery.reducer';
import { favouritesReducer } from './favourites/favourites.reducer';
import { orderReducer } from './order/order.reducer';
import { notificationsReducer } from './notifications/notifications.reducer';
import { storeReducer } from './store/store.reducer';
import { accessTokenReducer } from './accessToken/accessToken.reducer';
import { oneTimeReducer } from './oneTime/oneTime.reducer';
import { persistentReducer } from './persistent/persistent.reducer';
import smartLogOrders from './smartLogOrders';
import userlocationReducer from './userLocation/userlocationReducer';
import { navigationReducer } from './useMapfornav/useMapforNavReducer';
import { actvReducer } from './actv/actvReducer';

import rangeFilters from './rangeFilter/rangeFilterReducer';
import { filteredLists } from './filteredLists/filteredLists';
import { SelectedStoreReducer } from './selectedStore/selectedStore';

const rootReducer = combineReducers({
  favourites: favouritesReducer,
  cart: cartReducer,
  Smorder: smartLogOrders,
  store: storeReducer,
  delivery: deliveryReducer,
  order: orderReducer,
  oneTime: oneTimeReducer,
  persistent: persistentReducer,
  notifications: notificationsReducer,
  accessToken: accessTokenReducer,
  UserLocation: userlocationReducer,
  useMapfornavigation: navigationReducer,
  actvReducer: actvReducer,

  SelectedStoreReducer: SelectedStoreReducer,
  rangeFilter: rangeFilters,
  filteredLists: filteredLists,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
