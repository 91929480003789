import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import './i18n';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CookiesProvider } from 'react-cookie';
import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init('356857345818670', {} as AdvancedMatching, options);

const gtmParams = { id: 'GTM-TVCP25J' };

ReactDOM.render(
  <GTMProvider state={gtmParams}>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <CookiesProvider>
          <React.StrictMode>
            <React.Suspense fallback="loading">
              <App />
            </React.Suspense>
          </React.StrictMode>
        </CookiesProvider>
      </Provider>
    </Elements>
  </GTMProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
