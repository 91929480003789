export default {
  experience: process.env.PUBLIC_URL + '/images/icons/experienceBianco.png',
  callCocai: process.env.PUBLIC_URL + '/images/icons/callCocai.svg',
  food: {
    asiatico: process.env.PUBLIC_URL + '/images/icons/food/asiatico.png',
    burgers: process.env.PUBLIC_URL + '/images/icons/food/burgers.png',
    burgers2: process.env.PUBLIC_URL + '/images/icons/food/burgers2.png',
    carne: process.env.PUBLIC_URL + '/images/icons/food/carne.png',
    dolciegelati:
      process.env.PUBLIC_URL + '/images/icons/food/dolciegelati.png',
    dolciegelati2:
      process.env.PUBLIC_URL + '/images/icons/food/dolciegelati2.png',
    gastronomia: process.env.PUBLIC_URL + '/images/icons/food/gastronomia.png',
    mediterraneo:
      process.env.PUBLIC_URL + '/images/icons/food/mediterraneo.png',
    pizza: process.env.PUBLIC_URL + '/images/icons/food/pizza.png',
    poke: process.env.PUBLIC_URL + '/images/icons/food/poke.png',
    sushi: process.env.PUBLIC_URL + '/images/icons/food/sushi.png',
  },
  foodOrange: {
    asiatico: process.env.PUBLIC_URL + '/images/icons/foodOrange/asiatico.png',
    burgers: process.env.PUBLIC_URL + '/images/icons/foodOrange/burgers.png',
    burgers2: process.env.PUBLIC_URL + '/images/icons/foodOrange/burgers2.png',
    carne: process.env.PUBLIC_URL + '/images/icons/foodOrange/carne.png',
    dolciegelati:
      process.env.PUBLIC_URL + '/images/icons/foodOrange/dolciegelati.png',
    dolciegelati2:
      process.env.PUBLIC_URL + '/images/icons/foodOrange/dolciegelati2.png',
    gastronomia:
      process.env.PUBLIC_URL + '/images/icons/foodOrange/gastronomia.png',
    mediterraneo:
      process.env.PUBLIC_URL + '/images/icons/foodOrange/mediterraneo.png',
    pizza: process.env.PUBLIC_URL + '/images/icons/foodOrange/pizza.png',
    poke: process.env.PUBLIC_URL + '/images/icons/foodOrange/poke.png',
    sushi: process.env.PUBLIC_URL + '/images/icons/foodOrange/sushi.png',
  },
  drink: {
    beer_mug: process.env.PUBLIC_URL + '/images/icons/drink/beer-mug.png',
    cocktail: process.env.PUBLIC_URL + '/images/icons/drink/cocktail.png',
    cocktailkit: process.env.PUBLIC_URL + '/images/icons/drink/cocktailkit.png',
    liquori: process.env.PUBLIC_URL + '/images/icons/drink/liquori.png',
    softdrink: process.env.PUBLIC_URL + '/images/icons/drink/softdrink.png',
    vino: process.env.PUBLIC_URL + '/images/icons/drink/vino.png',
    vino2: process.env.PUBLIC_URL + '/images/icons/drink/vino2.png',
    vino4: process.env.PUBLIC_URL + '/images/icons/drink/vino4.png',
  },
  drinkOrange: {
    beer_mug: process.env.PUBLIC_URL + '/images/icons/drinkOrange/beer-mug.png',
    cocktail: process.env.PUBLIC_URL + '/images/icons/drinkOrange/cocktail.png',
    cocktailkit:
      process.env.PUBLIC_URL + '/images/icons/drinkOrange/cocktailkit.png',
    liquori: process.env.PUBLIC_URL + '/images/icons/drinkOrange/liquori.png',
    softdrinkOrange:
      process.env.PUBLIC_URL + '/images/icons/drink/softdrink.png',
    vino: process.env.PUBLIC_URL + '/images/icons/drinkOrange/vino.png',
    vino2: process.env.PUBLIC_URL + '/images/icons/drinkOrange/vino2.png',
    vino4: process.env.PUBLIC_URL + '/images/icons/drinkOrange/vino4.png',
  },
  grocieries: {
    aromiprofumi:
      process.env.PUBLIC_URL + '/images/icons/grocieries/aromiprofumi.png',
    macelleria:
      process.env.PUBLIC_URL + '/images/icons/grocieries/macelleria.png',
    ortofrutta:
      process.env.PUBLIC_URL + '/images/icons/grocieries/ortofrutta.png',
    panificati2:
      process.env.PUBLIC_URL + '/images/icons/grocieries/panificati2.png',
    panificati3:
      process.env.PUBLIC_URL + '/images/icons/grocieries/panificati3.png',
    pescheria:
      process.env.PUBLIC_URL + '/images/icons/grocieries/pescheria.png',
    prodotticaseari:
      process.env.PUBLIC_URL + '/images/icons/grocieries/prodotticaseari.png',
    salumi: process.env.PUBLIC_URL + '/images/icons/grocieries/salumi.png',
  },
  grocieriesOrange: {
    aromiprofumi:
      process.env.PUBLIC_URL +
      '/images/icons/grocieriesOrange/aromiprofumi.png',
    macelleria:
      process.env.PUBLIC_URL + '/images/icons/grocieriesOrange/macelleria.png',
    ortofrutta:
      process.env.PUBLIC_URL + '/images/icons/grocieriesOrange/ortofrutta.png',
    panificati2:
      process.env.PUBLIC_URL + '/images/icons/grocieriesOrange/panificati2.png',
    panificati3:
      process.env.PUBLIC_URL + '/images/icons/grocieriesOrange/panificati3.png',
    pescheria:
      process.env.PUBLIC_URL + '/images/icons/grocieriesOrange/pescheria.png',
    prodotticaseari:
      process.env.PUBLIC_URL +
      '/images/icons/grocieriesOrange/prodotticaseari.png',
    salumi:
      process.env.PUBLIC_URL + '/images/icons/grocieriesOrange/salumi.png',
  },
  shopping: {
    artigianato:
      process.env.PUBLIC_URL + '/images/icons/shopping/artigianato.png',
    bodycare: process.env.PUBLIC_URL + '/images/icons/shopping/bodycare.png',
    fiori: process.env.PUBLIC_URL + '/images/icons/shopping/fiori.png',
    petcare: process.env.PUBLIC_URL + '/images/icons/shopping/petcare.png',
    utilities: process.env.PUBLIC_URL + '/images/icons/shopping/utilities.png',
  },
  shoppingOrange: {
    artigianato:
      process.env.PUBLIC_URL + '/images/icons/shoppingOrange/artigianato.png',
    bodycare:
      process.env.PUBLIC_URL + '/images/icons/shoppingOrange/bodycare.png',
    fiori: process.env.PUBLIC_URL + '/images/icons/shoppingOrange/fiori.png',
    petcare:
      process.env.PUBLIC_URL + '/images/icons/shoppingOrange/petcare.png',
    utilities:
      process.env.PUBLIC_URL + '/images/icons/shoppingOrange/utilities.png',
  },

  logistics: {
    deposit:
      process.env.PUBLIC_URL + '/images/icons/logistics/deposit_bianco.png',
    deposit_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/deposit_blu.png',
    fountain:
      process.env.PUBLIC_URL + '/images/icons/logistics/fountain_bianco.png',
    fountain_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/fountain_blu.png',
    event: process.env.PUBLIC_URL + '/images/icons/logistics/event_bianco.png',
    event_b: process.env.PUBLIC_URL + '/images/icons/logistics/event_blu.png',
    pill: process.env.PUBLIC_URL + '/images/icons/logistics/pill_bianco.png',
    pill_b: process.env.PUBLIC_URL + '/images/icons/logistics/pill_blu.png',
    worldWide:
      process.env.PUBLIC_URL + '/images/icons/logistics/worldWide_bianco.png',
    worldWide_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/worldWide_blu.png',
    table: process.env.PUBLIC_URL + '/images/icons/logistics/table_bianco.png',
    table_b: process.env.PUBLIC_URL + '/images/icons/logistics/table_blu.png',
    around_me: process.env.PUBLIC_URL + '/images/icons/logistics/around_me.png',
  },
  logisticsOrange: {
    deposit:
      process.env.PUBLIC_URL + '/images/icons/logistics/deposit_giallo.png',
    deposit_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/deposit_blu.png',
    fountain:
      process.env.PUBLIC_URL + '/images/icons/logistics/fountain_giallo.png',
    fountain_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/fountain_blu.png',
    event: process.env.PUBLIC_URL + '/images/icons/logistics/event_giallo.png',
    event_b: process.env.PUBLIC_URL + '/images/icons/logistics/event_blu.png',
    pill: process.env.PUBLIC_URL + '/images/icons/logistics/pill_giallo.png',
    pill_b: process.env.PUBLIC_URL + '/images/icons/logistics/pill_blu.png',
    worldWide:
      process.env.PUBLIC_URL + '/images/icons/logistics/worldWide_bianco.png',
    worldWide_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/worldWide_blu.png',
    table: process.env.PUBLIC_URL + '/images/icons/logistics/table_bianco.png',
    table_b: process.env.PUBLIC_URL + '/images/icons/logistics/table_blu.png',
    around_me: process.env.PUBLIC_URL + '/images/icons/logistics/around_me.png',
  },
  profile: {
    add_location:
      process.env.PUBLIC_URL + '/images/icons/profile/add_location.svg',
    add_location_white:
      process.env.PUBLIC_URL + '/images/icons/profile/add_location_white.svg',
    bottle: process.env.PUBLIC_URL + '/images/icons/profile/bottle.svg',
    box: process.env.PUBLIC_URL + '/images/icons/profile/box.svg',
    briccola: process.env.PUBLIC_URL + '/images/icons/profile/briccola.svg',
    cocai_dx: process.env.PUBLIC_URL + '/images/icons/profile/cocai_dx.svg',
    cocai_hero: process.env.PUBLIC_URL + '/images/icons/profile/cocai_hero.svg',
    edit: process.env.PUBLIC_URL + '/images/icons/profile/edit.svg',
    heart_stroke:
      process.env.PUBLIC_URL + '/images/icons/profile/heart_stroke.svg',
    heart_fill: process.env.PUBLIC_URL + '/images/icons/profile/heart_fill.svg',
    location: process.env.PUBLIC_URL + '/images/icons/profile/location.svg',
    cocai_sx: process.env.PUBLIC_URL + '/images/icons/profile/cocai_sx.svg',
    logout: process.env.PUBLIC_URL + '/images/icons/profile/cocai_logout.svg',
    wallet: process.env.PUBLIC_URL + '/images/icons/profile/wallet.png',
    question:
      process.env.PUBLIC_URL + '/images/icons/profile/cocai_question.svg',
    phone: process.env.PUBLIC_URL + '/images/icons/profile/phone.svg',

    remove: process.env.PUBLIC_URL + '/images/icons/profile/remove.svg',
    remove_yellow:
      process.env.PUBLIC_URL + '/images/icons/profile/remove_yellow.svg',
  },
  arrow: process.env.PUBLIC_URL + '/images/icons/arrow.svg',
  arrowSlim: process.env.PUBLIC_URL + '/images/icons/rtarrow.svg',
  bag: process.env.PUBLIC_URL + '/images/icons/bag.svg',
  cash: process.env.PUBLIC_URL + '/images/icons/cash.svg',
  coupon: process.env.PUBLIC_URL + '/images/icons/coupon.svg',
  credit_card: process.env.PUBLIC_URL + '/images/icons/credit_card.svg',
  church: process.env.PUBLIC_URL + '/images/icons/church.svg',
  delivery: process.env.PUBLIC_URL + '/images/icons/delivery.svg',
  deliveryPopUp: process.env.PUBLIC_URL + '/images/icons/deliveryPopUp.svg',
  deliveryMan: process.env.PUBLIC_URL + '/images/icons/delivery-man.svg',
  delivery_bag_none:
    process.env.PUBLIC_URL + '/images/icons/delivery_bag_0.svg',
  delivery_bag_ok: process.env.PUBLIC_URL + '/images/icons/delivery_bag_1.svg',
  favourite: process.env.PUBLIC_URL + '/images/icons/favourite.svg',
  favourite_black: process.env.PUBLIC_URL + '/images/icons/favourite-black.svg',
  favourite_empty: process.env.PUBLIC_URL + '/images/icons/favourite-empty.svg',
  ferry: process.env.PUBLIC_URL + '/images/icons/ferry.svg',
  flag: process.env.PUBLIC_URL + '/images/icons/flag.svg',
  fountain: process.env.PUBLIC_URL + '/images/icons/fountain.svg',
  gondolier: process.env.PUBLIC_URL + '/images/icons/gondolier.svg',
  info: process.env.PUBLIC_URL + '/images/icons/info_icon.svg',
  location: process.env.PUBLIC_URL + '/images/icons/location.svg',
  minus: process.env.PUBLIC_URL + '/images/icons/minus.svg',
  minus2: process.env.PUBLIC_URL + '/images/icons/minus2.svg',
  museum: process.env.PUBLIC_URL + '/images/icons/museum.svg',
  offer: process.env.PUBLIC_URL + '/images/icons/offer.svg',
  okvotes: process.env.PUBLIC_URL + '/images/icons/okvotes.svg',
  park: process.env.PUBLIC_URL + '/images/icons/park.svg',
  pickup: process.env.PUBLIC_URL + '/images/icons/pickup.svg',
  plus: process.env.PUBLIC_URL + '/images/icons/plus_icon.svg',
  plus2: process.env.PUBLIC_URL + '/images/icons/plus_icon2.svg',
  povero_gabbiano: process.env.PUBLIC_URL + '/images/icons/povero_gabbiano.svg',
  public_place: process.env.PUBLIC_URL + '/images/icons/public-place.svg',
  star: process.env.PUBLIC_URL + '/images/icons/star.svg',
  table: process.env.PUBLIC_URL + '/images/icons/table.svg',
  time: process.env.PUBLIC_URL + '/images/icons/time.svg',
  time_white: process.env.PUBLIC_URL + '/images/icons/time_white.svg',
  wc: process.env.PUBLIC_URL + '/images/icons/wc.svg',
};
