// Action Types

const CHANGE_FILTERS = 'CHANGE_FILTERS';

// Initial State
const initialState: any = {
  lists: [],
};

// Reducer
export function filteredLists(
  state = initialState,
  action: { type: any; payload: any },
) {
  switch (action.type) {
    case CHANGE_FILTERS:
      return {
        lists: action.payload.nav,
      };
    default:
      return state;
  }
}

// Action Creators

export function setfilterlists(nav: any) {
  return { type: CHANGE_FILTERS, payload: { nav } };
}
