export default {
  cibo: {
    imageUrl:
      process.env.PUBLIC_URL + '/images/navigationIcons/bottomSheet/food.png',
    alt: 'bigIcons.cibo',
  },
  bevande: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/bottomSheet/bevande.png',
    alt: 'bigIcons.bevande',
  },
  lightCatering: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/bottomSheet/catering.png',

    alt: 'bigIcons.lightCatering',
  },
  Botteghe: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/bottomSheet/botteghe.png',

    alt: 'bigIcons.Botteghe',
  },
  onDemand: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/bottomSheet/ondemand.png',
    alt: 'bigIcons.onDemand',
  },
  tableBooking: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/bottomSheet/tableBooking.svg',
    alt: 'bigIcons.tableBooking',
  },

  smart_logistic: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/bottomSheet/luggagedelivery_icona.png',
    alt: 'bigIcons.smart_logistic',
  },
};
