import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.css';
import { BigIconButton, EBigIconButtonType } from '../bigIconButton';
import { useList, useNavigation, useTranslate } from '@pankod/refine-core';
import useLongPress from 'hooks/useLongPress';
import { Store } from 'interfaces';
import images from '../../constants/images';
import { number } from 'yup';
import filterListItems from 'constants/filterListItems';

export enum EStoreFilters {
  cibo = 'cibo',
  bevande = 'bevande',
  spesa = 'spesa',
  botteghe = 'acquisti',
  consigliati = 'consigliati',
  preferiti = 'preferiti',
  tableBooking = 'tableBooking',
}

function RadialMenu(props: any) {
  const menuButtonRef = useRef<any>(null);
  const menuButtonRef2 = useRef<any>(null);
  const [totalStores, SetTotalStores] = useState<any>();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/food/store`)
      .then(res => res.json())
      .then(res => {
        const it = [
          { type: EBigIconButtonType.cibo, titleKey: 'Cibo' },
          { type: EBigIconButtonType.bevande, titleKey: 'Spesa' },
          {
            type: EBigIconButtonType.luggageDelivery,
            titleKey: 'luggageDelivery',
          },
          {
            type: EBigIconButtonType.lightCatering,
            titleKey: 'Catering',
          },
          { type: EBigIconButtonType.botteghe, titleKey: 'Botteghe' },
          { type: EBigIconButtonType.onDemand, titleKey: 'Spedizioni' },
          { type: EBigIconButtonType.tableBooking, titleKey: 'Booking' },
          { type: EBigIconButtonType.experience, titleKey: 'Experience' },
          { type: EBigIconButtonType.H20, titleKey: 'H2O' },
          { type: EBigIconButtonType.pills, titleKey: 'Gems' },
          { type: EBigIconButtonType.eventi, titleKey: 'Eventi' },
          // { type: EBigIconButtonType.dipositi, titleKey: 'depositi' },
        ];
        SetTotalStores([...it, ...res.data]);
      });
  }, []);
  const circleRef = useRef<any>(null);

  const t = useTranslate();

  const navigateToPage = useCallback((filter: any) => {
    let state = { state: EStoreFilters.cibo };

    let href = '';

    switch (filter) {
      case EBigIconButtonType.cibo:
      case 'cibo':
        state = { state: EStoreFilters.cibo };

        break;
      case EBigIconButtonType.bevande:
      case filterListItems.bevande.id:
        state = { state: EStoreFilters.bevande || EStoreFilters.spesa };
        break;
      case EBigIconButtonType.luggageDelivery:
        return '/webapp/luggageDelivery';
        // push('/luggageDelivery');
        break;
      case EBigIconButtonType.lightCatering:
        return '/webapp/light_cathering';
        // push('/light_cathering');
        break;
      case EBigIconButtonType.botteghe:
      case filterListItems.Botteghe.id:
        state = { state: EStoreFilters.botteghe };
        break;
      case EBigIconButtonType.pills:
      case filterListItems.pills.id:
        state = { state: filterListItems.pills.id };
        break;
      case EBigIconButtonType.tableBooking:
        state = { state: EStoreFilters.tableBooking };
        break;
      case 105:
        state = { state: EStoreFilters.tableBooking };
        break;
      case EBigIconButtonType.onDemand:
        return '/webapp/ondemand';
        // push('/ondemand');
        break;
      case filterListItems.experience.id:
      case EBigIconButtonType.experience:
        state = { state: filterListItems.experience.id };

        break;

      case EBigIconButtonType.eventi:
      case filterListItems.events.id:
        state = { state: filterListItems.events.id };
        break;

      default:
        return `/webapp/stores/${filter}`;
    }
    const queryParams = new URLSearchParams(state).toString();
    href = `/webapp/stores?${queryParams}`;
    return href;
  }, []);

  const [storedItems, setStoredItems] = useState<any>([]);

  useEffect(() => {
    const updateStoredItems = () => {
      const items = localStorage.getItem('pinnedTab');
      if (items) {
        setStoredItems(JSON.parse(items));
      }
    };

    // Initial loading of items
    updateStoredItems();

    const handleStorageChange = () => {
      updateStoredItems();
    };

    window.addEventListener('storage', handleStorageChange);

    // clean up event handler
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  function isStorePinned(tab: any) {
    return storedItems.some(
      (Tabs: any) => Tabs.type == tab.type || Tabs.type === tab.id,
    );
  }
  const menuButton = menuButtonRef.current;
  const circle = circleRef.current;

  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (totalStores) {
      let items;
      if (circle) {
        items = circle.querySelectorAll('a');
      }
      if (menuButton) {
        menuButton.onmouseover = function (e: { preventDefault: () => void }) {
          e.preventDefault();
          if (circle) {
            circle.classList.add('open');
          }
        };
        menuButton.onclick = function (e: { preventDefault: () => void }) {
          e.preventDefault();
          if (circle) {
            circle.classList.toggle('open');

            setToggle(!toggle);
          }
        };
      }
      console.log('the itmes ', items?.length);
      console.log('items?.', items, 'storedItems', storedItems);

      for (let i = 0, l = storedItems?.length; i < l; i++) {
        items[i].style.left =
          (
            47 -
            35 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + '%';
        items[i].style.top =
          (
            50 +
            35 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + '%';
      }
    }
  }, [
    circleRef.current,
    circle,
    menuButtonRef.current,
    menuButton,
    storedItems,
    totalStores,
  ]);

  const handleMouseLeave = () => {
    if (circle) {
      circle.classList.remove('open');
    }
  };

  useEffect(() => {
    const itemsFromStorage = localStorage.getItem('pinnedTab');

    // Check if 'pinnedStores' exists in local storage
    if (itemsFromStorage) {
      setStoredItems(JSON.parse(itemsFromStorage));
    } else if (itemsFromStorage === null) {
      // If 'pinnedStores' does not exist in local storage,
      // set all items as pinned and store them in local storage
      const allItems = [
        { type: EBigIconButtonType.cibo, titleKey: 'cibo' },
        { type: EBigIconButtonType.bevande, titleKey: 'spesa' },
        {
          type: EBigIconButtonType.luggageDelivery,
          titleKey: 'luggageDelivery',
        },
        { type: EBigIconButtonType.lightCatering, titleKey: 'Catering' },
        { type: EBigIconButtonType.botteghe, titleKey: 'botteghe' },
        { type: EBigIconButtonType.onDemand, titleKey: 'Spedizioni' },
      ];

      localStorage.setItem('pinnedTab', JSON.stringify(allItems));
      setStoredItems(allItems);
    }
  }, []);

  function triggerVibration(duration: number) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'vibrate', duration }),
      );
    } else {
      // Fallback if running in a regular browser
      if ('vibrate' in navigator) {
        navigator.vibrate(duration);
      }
    }
  }

  // long press detector
  const [infoRadial, setInfoRadial] = useState<any>();

  const onLongPress = (e: any) => {
    const item = e.target.getAttribute('alt');
    switch (item) {
      case 'bigIcons.cibo':
        setInfoRadial(EBigIconButtonType.cibo);
        break;
      case 'bigIcons.bevande':
        setInfoRadial(EBigIconButtonType.bevande);

        break;
      case 'bigIcons.lightCatering':
        setInfoRadial(EBigIconButtonType.lightCatering);

        break;
      case 'bigIcons.smart_logistic':
        setInfoRadial(EBigIconButtonType.luggageDelivery);

        break;
      case 'bigIcons.onDemand':
        setInfoRadial(EBigIconButtonType.onDemand);

        break;
      case 'bigIcons.Botteghe':
        setInfoRadial(EBigIconButtonType.botteghe);

        break;

      case 'bigIcons.tableBooking':
        setInfoRadial(EBigIconButtonType.tableBooking);

        break;

      default:
        setInfoRadial(EBigIconButtonType.cibo);
        break;
    }
  };

  const onClick = () => {
    console.log('clicked');
  };

  const defaultOptions = {
    shouldPreventDefault: false,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    (e: any) => onLongPress(e),
    onClick,
    defaultOptions,
  );

  return (
    <div className="container ">
      <nav style={{ marginTop: '-100px' }} onMouseLeave={handleMouseLeave}>
        <div style={{ zIndex: '999999' }}>
          <div>
            <div ref={circleRef} className="circle">
              {totalStores
                ?.filter((item: any) => isStorePinned(item))
                .map((item: any, index: number) => (
                  <div
                    {...longPressEvent}
                    onClick={() => triggerVibration(100)}
                    key={index}>
                    <a href={navigateToPage(item?.id ? item.id : item.type)}>
                      <BigIconButton
                        imgSrc={
                          item?.img_path
                            ? `${images.store_img_url}${item.id}/${item.img_path}`
                            : undefined
                        }
                        store={item?.business_name ? item : false}
                        infoRadial={infoRadial}
                        withOpacity={true}
                        type={item.type}
                        title={
                          item?.business_name
                            ? item.business_name
                            : t(`${item.titleKey}`)
                        }
                        onClick={() => console.log('nav')}
                      />
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div>
          {
            <a ref={menuButtonRef} className="menu-button">
              {props.middleComp}
            </a>
          }
        </div>
      </nav>
    </div>
  );
}

export default RadialMenu;
