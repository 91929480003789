// Action Types
const TOGGLE_MAP_NAVIGATION = 'TOGGLE_MAP_NAVIGATION';
const CHANGE_NAV_MODE = 'CHANGE_NAV_MODE';
const ZOOMOUT_TOGGLE = 'ZOOMOUT_TOGGLE';

// Initial State
const initialState: any = {
  useMapForNavigation: false,
  zoomOut: false,
};

// Reducer
export function navigationReducer(
  state = initialState,
  action: { type: any; payload: any },
) {
  switch (action.type) {
    case TOGGLE_MAP_NAVIGATION:
      return {
        ...state,
        useMapForNavigation: !state.useMapForNavigation,
      };
    case ZOOMOUT_TOGGLE:
      return {
        ...state,
        zoomOut: action.payload.zoomOut,
      };
    case CHANGE_NAV_MODE:
      return {
        useMapForNavigation: action.payload.nav,
      };
    default:
      return state;
  }
}

// Action Creators
export function toggleMapNavigation() {
  return { type: TOGGLE_MAP_NAVIGATION };
}

export function changeNavMode(nav: boolean) {
  return { type: CHANGE_NAV_MODE, payload: { nav } };
}

export function toggleZoom(zoomOut: boolean) {
  return { type: ZOOMOUT_TOGGLE, payload: { zoomOut } };
}
