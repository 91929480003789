import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { DateTime } from 'luxon';
import { OrderWithRelations } from '../models/Order';
import { OrderPaymentMethodEnum } from '../types/orderPaymentMethodEnum';

export interface orderState {
  order: OrderWithRelations;
}

const initialState: orderState = {
  order: {
    store_id: 0,
    user_id: 0,
    date: undefined,
    payment_method: OrderPaymentMethodEnum.CreditCard,
    total: 0,
    custom_discount: 0,
    note: null,
    number: '',
    //orderType: null,
    //order_type: null,
    food_orders: null,
    smart_logistics_orders: null,
    table_orders: null,
    extra_info: null,
    delivery_price_: null,
  },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,

  reducers: {
    addorderData: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.order = { ...state.order, ...action.payload };
      }
      console.log('order_STATE:', state.order);
    },
    clearorder: state => {
      state.order = initialState.order;
      console.log('order_STATE:', state.order);
    },
  },
});

export const { addorderData, clearorder } = orderSlice.actions;
export default orderSlice.reducer;
