import React, { useEffect, useRef, useState } from 'react';
import {
  useCreate,
  useGetIdentity,
  useLogin,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  AntdLayout,
  Divider,
  notification,
} from '@pankod/refine-antd';
import { ESocialButtonType, SocialButton } from '../../components/socialButton';
import { Link, useLocation } from 'react-router-dom';
import { NavBar } from '../../components/navBar';
import { useSearchParams } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import OTP_VERIFY from '../../components/otpVerification';
import { initializeApp } from 'firebase/app';
import * as OrderActionsRedux from '../../store/order/order.actions';
import * as TokenActionsRedux from '../../store/accessToken/accessToken.actions';
import UserHandler from '../../util/UserHandler';
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
// import {
//   getAuth,
//   signInWithPhoneNumber,
//   RecaptchaVerifier,
// } from 'firebase/auth';

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from 'firebase/app-check';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { addorderData } from '../../store/smartLogOrders';
import { RootState } from '../../store';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const { Title } = Typography;
// Initialize App Check
//889A29CE-BA2D-4CA2-8715-2C0BBE57CB61

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_reCAPTCHA_TOKEN),
  isTokenAutoRefreshEnabled: true, // Optionally, automatically refresh App Check token
});
self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const PhoneLogin: React.FC = () => {
  const dispatch = useDispatch();

  const { mutate, isSuccess } = useCreate();
  const { mutate: login } = useLogin();
  const [user, setUser] = useState<any>();
  const location = useLocation();

  const isFoodOrder = location?.state?.anonymusUser?.state?.isfoodOrder;
  const table = location.state?.anonymusUser?.state?.table;

  const from = location.state?.anonymusUser?.state?.from;
  const allOverTheworld = location?.state?.anonymusUser?.state?.allOverTheworld;
  const slotsAvailable = location?.state?.anonymusUser?.state?.slotsAvailable;
  const walkingTime = location?.state?.anonymusUser?.state?.walkingTime;

  // Access the state prop here
  const signUpForm = location?.state?.values;
  const anonymusUser = location?.state?.anonymusUser;
  const PhoneFromSignUp = location?.state?.telValue;
  const order = useSelector((state: RootState) => state.Smorder);
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const dispatchRedux = useDispatch();
  const { push, replace, goBack } = useNavigation();

  const t = useTranslate();
  const { data: usr } = useGetIdentity();

  useEffect(() => {
    if (usr) {
      replace('/home');
    }
  }, [usr]);

  const [searchParams] = useSearchParams();
  const err = useRef(searchParams.get('err'));

  const openNotificationWithIcon = (type: NotificationType, code: string) => {
    notification[type]({
      message: t(type),
      description: t(`${code}`),
    });
  };

  useEffect(() => {
    if (err && err.current) {
      openNotificationWithIcon('error', err.current);
    }
  }, [err]);

  const [value, setValue] = useState('');
  const [shoowOtpFeild, SetShowOtp] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  useEffect(() => {
    // Optional: Get the current App Check token
    getToken(appCheck)
      // .then(tokenResponse => {
      //   console.log('App Check token:');
      // })
      .catch(er => {
        console.error('Unable to get App Check token', er);
      });
  }, []);

  useEffect(() => {
    // Set up interval to update time every second
    if (shoowOtpFeild && timeLeft > 0) {
      const timer = setInterval(() => {
        // Decrease time left by 1 second

        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);

      // Clear interval when component unmounts
      return () => clearInterval(timer);
    }
  }, [shoowOtpFeild, timeLeft]);

  useEffect(() => {
    if (signUpForm && PhoneFromSignUp) {
      setValue(PhoneFromSignUp);
    }
    if (anonymusUser) {
      setValue(anonymusUser?.phone);
    }
  }, [signUpForm, anonymusUser?.phone]);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: response => {
              console.log('reCAPTCHA solved');
            },
            'expired-callback': () => {
              openNotificationWithIcon(
                'error',
                'reCAPTCHA expired reload page and try again',
              );
              console.log('reCAPTCHA expired');
            },
          },
        );
        window.recaptchaVerifier
          .render()
          .then(widgetId => {
            console.log('reCAPTCHA rendered with widgetId:', widgetId);
          })
          .catch(error => {
            console.error('Error rendering reCAPTCHA', error);
          });
      } catch (error) {
        console.error('Error setting up reCAPTCHA', error);
      }
    }
  };
  const handleSendCode = (reCAPTCHA: boolean) => {
    setupRecaptcha();

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(
      auth,
      value.includes('+') ? value : `+${value}`,
      appVerifier,
    )
      .then((Cr: any) => {
        SetShowOtp(true);
        setConfirmationResult(Cr);
        openNotificationWithIcon('success', 'SMS sent');
        console.log('Confirmation result:', Cr);
      })
      .catch(error => {
        console.error('Error during signInWithPhoneNumber', error);
        if (error.code === 'auth/operation-not-allowed') {
          openNotificationWithIcon('error', 'Internal error');
          // alert(
          //   'Phone authentication is not enabled. Please enable it in the Firebase console.',
          // );
        } else if (error.code === 'auth/invalid-app-credential') {
          openNotificationWithIcon(
            'error',
            'Invalid reCAPTCHA token. Please refresh and try again.',
          );
        } else {
          openNotificationWithIcon('error', ' Please refresh and try again.');
        }
      });
  };
  const handleVerifyCode = async () => {
    try {
      if (!confirmationResult) {
        console.error('No confirmation result available');
        openNotificationWithIcon(
          'error',
          'Please request a verification code first.',
        );

        return false;
      }

      const result = await confirmationResult.confirm(verificationCode);
      const Ur = result.user;
      if (anonymusUser) {
        signGhostUser(anonymusUser);
      }
      if (signUpForm && PhoneFromSignUp) {
        mutate({
          resource: 'user/signup',
          values: { ...signUpForm, phone: `+${value}`, phone_verified: true },
        });
      }

      console.log('User:', Ur);
      return true;
    } catch (error) {
      console.error('Error during confirmation', error);
      openNotificationWithIcon('error', error.message);

      return false;
    }
  };

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
  }, []);

  useEffect(() => {
    if (isSuccess) {
      openNotificationWithIcon('success', 'Verified');
      login({ email: signUpForm.email, password: signUpForm.password });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (anonymusUser) {
      if (user && isFoodOrder === true) {
        dispatchRedux(OrderActionsRedux.setuser(user.user));
        dispatchRedux(TokenActionsRedux.pushAccessToken(user.accessToken));
        UserHandler.getInstance().ghostToken = user.accessToken;
        ReactPixel.fbq('track', 'CONTACT', { eventID: 'EVENT_ID' });
        // replace('/paymentmethod');
        push('/payment', {
          state: {
            isfoodOrder: isFoodOrder,
            table: table,
            from,
            slotsAvailable,
            walkingTime,
          },
        });
      } else if (user && isFoodOrder === false) {
        // console.log(user);

        dispatchRedux(OrderActionsRedux.setuser(user.user));
        dispatchRedux(TokenActionsRedux.pushAccessToken(user.accessToken));
        UserHandler.getInstance().ghostToken = user.accessToken;
        ReactPixel.fbq('track', 'CONTACT', { eventID: 'EVENT_ID' });
        push('/payment', {
          state: {
            table: table,
            isfoodOrder: isFoodOrder,
            allOverTheworld,
            from,
            slotsAvailable,
            walkingTime,
          },
        });
        // replace('/payment', { state: { isfoodOrder: true } });
      }
    }
  }, [user]);

  const signGhostUser = async data => {
    console.log('data', data);

    const headers = {
      accept: 'application/json',
      'content-type': 'application/json',
    };
    const response = await axios
      .post(`${process.env.REACT_APP_API}/user/signup`, data, {
        headers: headers,
      })
      .then(res => res.data)

      .catch(erer => openNotificationWithIcon('error', erer));
    dispatch(
      addorderData({
        user_id: response.user.id,

        smart_logistics_orders: {
          ...order.order.smart_logistics_orders,
        },
      }),
    );

    console.log(response);

    setUser(response);
    return response;
  };

  return (
    <div className="login">
      <div
        style={{
          paddingLeft: '15px',
          position: 'absolute',
          paddingTop: '15px',
          zIndex: '14',
        }}>
        <NavBar back={true} onBack={() => goBack()} />
      </div>

      <div className="login__container">
        <div className="login__container__imagecontainer">
          <span>&nbsp;</span>
          <img
            className="login__container__cocaisign"
            src={'cocaiexpress.svg'}
            alt={t('login.logoalt')}
          />
          <img
            className="login__container__cocaibarch"
            src={`${process.env.PUBLIC_URL}/images/cocai_barch.svg`}
            alt="cocai barch"
          />
          <img
            className="login__container__waves"
            src={`${process.env.PUBLIC_URL}/images/waves_background.png`}
            alt="waves"
          />
        </div>
        <Card
          title={''}
          className="login__container__loginCard"
          headStyle={{ borderBottom: 0 }}>
          <div style={{ marginTop: '30px' }}>
            <p className="verify-phone-heading">
              {t('track_order.confirm_phone')}
            </p>
            <PhoneInput
              country={'it'}
              value={value}
              onChange={text => setValue(text)}
              inputStyle={{
                marginLeft: '50px',
                // backgroundColor: 'grey',
                // border: 'none',
                // outline: 'none',
                width: '85%',
                fontSize: '16px',
                padding: '10px 10px',
              }}
            />
          </div>
          <div style={{ marginTop: '30px' }}>
            <Button
              onClick={() => {
                handleSendCode(true);
              }}
              disabled={shoowOtpFeild}
              style={{ margin: 0 }}
              type="primary"
              size="large"
              htmlType="submit"
              block>
              {t('track_order.send')}
            </Button>
          </div>
          {shoowOtpFeild && (
            <div style={{ marginTop: '30px' }}>
              <OTP_VERIFY
                seTheCode={e => setVerificationCode(e)}
                verifyOtpPhone={handleVerifyCode}
                phone={true}
                email={value.includes('+') ? value : `+${value}`}
                getorder={() => console.log('d')}
                num={'orderNumber'}
                timeLeft={timeLeft}
                resend={
                  <div className="otp_send_container">
                    <Button
                      style={{
                        backgroundColor:
                          Number(timeLeft) > 0 ? 'grey' : '#ffca2c',
                        color: 'black',
                      }}
                      size="large"
                      disabled={Number(timeLeft) > 0}
                      onClick={() => {
                        const appVerifier = window.recaptchaVerifier;
                        signInWithPhoneNumber(
                          auth,
                          value.includes('+') ? value : `+${value}`,
                          appVerifier,
                        )
                          .then((Cr: any) => {
                            setConfirmationResult(Cr);

                            openNotificationWithIcon('success', 'SMS sent');
                          })
                          .catch(error => {
                            console.error(
                              'Error during signInWithPhoneNumber',
                              error,
                            );
                            if (error.code === 'auth/operation-not-allowed') {
                              openNotificationWithIcon(
                                'error',
                                'Phone authentication is not enabled. Please enable it in the Firebase console.',
                              );
                            } else if (
                              error.code === 'auth/invalid-app-credential'
                            ) {
                              openNotificationWithIcon(
                                'error',
                                'Invalid reCAPTCHA token. Please refresh and try again.',
                              );
                            } else {
                              openNotificationWithIcon(
                                'error',
                                ' Please refresh and try again.',
                              );
                            }
                          });

                        // initiateOTPVerification(orderNumber);
                      }}>
                      {t('track_order.resend')}
                    </Button>
                  </div>
                }></OTP_VERIFY>
            </div>
          )}
          {/* <Row
            justify="center"
            align="middle"
            style={{ marginBottom: 25, display: 'flex' }}>
            <Col
              onClick={() =>
                window.location.replace(
                  `${process.env.REACT_APP_API}/user/auth/google`,
                )
              }
              span={24}
              className="Social_logins">
              <SocialButton
                type={ESocialButtonType.google}
                onClick={() =>
                  window.location.replace(
                    `${process.env.REACT_APP_API}/user/auth/google`,
                  )
                }
              />
              <p className="Social_logins_text">{t('login.google_login')}</p>
            </Col>
            <Col
              onClick={() =>
                window.location.replace(
                  `${process.env.REACT_APP_API}/user/auth/apple`,
                )
              }
              span={24}
              className="Social_logins">
              <SocialButton
                type={ESocialButtonType.apple}
                onClick={() =>
                  window.location.replace(
                    `${process.env.REACT_APP_API}/user/auth/apple`,
                  )
                }
              />
              <p className="Social_logins_text">{t('login.apple_login')}</p>
            </Col>
          </Row> */}
          {/* <div className="login__divider">
            <span className="login__divider__text">
              {t('login.continueWithEmail')}
            </span>
          </div> */}

          {/* <Row justify="space-around" className="login__signupcall">
            <Col
              onClick={() => {
                push('/home');
              }}
              span={24}
              className="Social_logins_email"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginBottom: '10px',
              }}>
              <p
                style={{ marginLeft: 'auto' }}
                className="Social_logins_email_text">
                {t('ready.continue')}
              </p>
            </Col>
          </Row> */}

          {/* <Row justify="space-around" className="login__signupcall">
            <Col
              onClick={() => {
                push('/signup');
              }}
              span={24}
              className="Social_logins_email"
              style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className="Social_logins_email_image"
                src={`${process.env.PUBLIC_URL}/images/icons/profile/bottle.svg`}
                alt="email"
              />
              <p className="Social_logins_email_text">
                {t('login.loginEmail')}
              </p>
            </Col>
          </Row> */}

          {/* <Row justify="space-around" className="login__signupcall">
            <div style={{ marginTop: '15px' }}>
              {t('login.alreadyHaveAccount')}
              <Link className="Link_login_page" to={'/loginPage'}>
                {t('login.login')}
              </Link>
            </div>
          </Row> */}
          {/* <Row justify="space-around" className="login__privacy">
            <div>
              <span>
                continuando, acceti automaticamente i nostri{' '}
                <Link onClick={() => openPDF()}> termini e condizioni</Link>,{' '}
                <Link to={'/privacy'}>Politica sulla {t('login.privacy')}</Link>{' '}
              </span>
            </div>
          </Row> */}
        </Card>
      </div>
    </div>
  );
};
