export default [
  { id: 91, text: 'San Marco' },
  { id: 447, text: 'San Polo' },
  { id: 502, text: 'Dorsoduro' },
  { id: 119, text: 'Cannaregio' },
  { id: 216, text: 'Castello' },
  { id: 516, text: 'Santa Croce' },
  { id: 137, text: 'Giudecca' },
  { id: 303, text: 'Sacca Fisola' },
  { id: 167, text: 'Sant Elena' },
];
