const SET_RANGE_FILTER = 'SET_RANGE_FILTER';
const CLEAR_RANGE_FILTER = 'CLEAR_RANGE_FILTER';

export const setRangeFilters = (range: number) => ({
  type: SET_RANGE_FILTER,
  payload: { range },
});

export const clearUserLocation = () => ({
  type: CLEAR_RANGE_FILTER,
});

const initialState = {
  range: 2000,
};

const rangeFilters = (
  state = initialState,
  action: { type: any; payload: { range: number } },
) => {
  switch (action.type) {
    case SET_RANGE_FILTER:
      return {
        range: action.payload.range,
      };
    case CLEAR_RANGE_FILTER:
      return initialState;
    default:
      return state;
  }
};

export default rangeFilters;
