import { StoreSubcategoryEnum } from 'types/stores/storeSubcategoryEnum';
import { OtherFiltersEnum } from 'types/otherFilters';
import { EFilterType, IFilterItem } from '../components/filterList/filterItem';
import icons from '../constants/icons';
import { getDistance } from '../util/getDistance';
import bigIcons from './bigIcons';
import bigIconsBottomSheet from './bigIconsBottomSheet';
import { EBigIconButtonType } from 'components/bigIconButton';

/**
 * Filtri per il cibo
 */
const asiatico: IFilterItem = {
  id: StoreSubcategoryEnum.Asiatico,
  title: 'asiatico',
  icon: icons.food.asiatico,
  type: EFilterType.food,
};
const burgers: IFilterItem = {
  id: StoreSubcategoryEnum.Burgers,
  title: 'burgers',
  icon: icons.food.burgers2,
  type: EFilterType.food,
};
const gastronomia: IFilterItem = {
  id: StoreSubcategoryEnum.Gastronomia,
  title: 'gastronomia',
  icon: icons.food.gastronomia,
  type: EFilterType.food,
};
const mediterraneo: IFilterItem = {
  id: StoreSubcategoryEnum.Mediterraneo,
  title: 'mediterraneo',
  icon: icons.food.mediterraneo,
  type: EFilterType.food,
};
const sushi: IFilterItem = {
  id: StoreSubcategoryEnum.Sushi,
  title: 'sushi',
  icon: icons.food.sushi,
  type: EFilterType.food,
};
const poke: IFilterItem = {
  id: StoreSubcategoryEnum.Poke,
  title: 'poke',
  icon: icons.food.poke,
  type: EFilterType.food,
};
const pizza: IFilterItem = {
  id: StoreSubcategoryEnum.Pizza,
  title: 'pizza',
  icon: icons.food.pizza,
  type: EFilterType.food,
};
const carne: IFilterItem = {
  id: StoreSubcategoryEnum.Carne,
  title: 'carne',
  icon: icons.food.carne,
  type: EFilterType.food,
};
const dolciegelati: IFilterItem = {
  id: StoreSubcategoryEnum.Dolciegelati,
  title: 'dolci_e_gelati',
  icon: icons.food.dolciegelati2,
  type: EFilterType.food,
};

/**
 * Filtri per le bevande
 */
const birra: IFilterItem = {
  id: StoreSubcategoryEnum.Birra,
  title: 'birra',
  icon: icons.drink.beer_mug,
  type: EFilterType.food,
};
const cocktail: IFilterItem = {
  id: StoreSubcategoryEnum.Cocktail,
  title: 'cocktail',
  icon: icons.drink.cocktail,
  type: EFilterType.food,
};
const cocktailkit: IFilterItem = {
  id: StoreSubcategoryEnum.Cocktailkit,
  title: 'cocktailkit',
  icon: icons.drink.cocktailkit,
  type: EFilterType.food,
};
const liquoriedistillati: IFilterItem = {
  id: StoreSubcategoryEnum.Liquoriedistillati,
  title: 'liquori_e_distillati',
  icon: icons.drink.liquori,
  type: EFilterType.food,
};
const softdrink: IFilterItem = {
  id: StoreSubcategoryEnum.Softdrinks,
  title: 'soft_drinks',
  icon: icons.drink.softdrink,
  type: EFilterType.food,
};
const vino: IFilterItem = {
  id: StoreSubcategoryEnum.Vino,
  title: 'vino',
  icon: icons.drink.vino2,
  type: EFilterType.food,
};

/**
 * Filtri per gli spesa
 */
const aromiprofumi: IFilterItem = {
  id: StoreSubcategoryEnum.Aromieprofumi,
  title: 'aromi_e_profumi',
  icon: icons.grocieries.aromiprofumi,
  type: EFilterType.food,
};
const macelleria: IFilterItem = {
  id: StoreSubcategoryEnum.Macelleria,
  title: 'macelleria',
  icon: icons.grocieries.macelleria,
  type: EFilterType.food,
};
const ortofrutta: IFilterItem = {
  id: StoreSubcategoryEnum.Ortofrutta,
  title: 'ortofrutta',
  icon: icons.grocieries.ortofrutta,
  type: EFilterType.food,
};
const panificati: IFilterItem = {
  id: StoreSubcategoryEnum.Panificati,
  title: 'panificati',
  icon: icons.grocieries.panificati2,
  type: EFilterType.food,
};
const pescheria: IFilterItem = {
  id: StoreSubcategoryEnum.Pescheria,
  title: 'pescheria',
  icon: icons.grocieries.pescheria,
  type: EFilterType.food,
};
const prodotticaseari: IFilterItem = {
  id: StoreSubcategoryEnum.Prodotti_caseari,
  title: 'prodotti_caseari',
  icon: icons.grocieries.prodotticaseari,
  type: EFilterType.food,
};
const salumi: IFilterItem = {
  id: StoreSubcategoryEnum.Salumieaffettati,
  title: 'salumi_e_affettati',
  icon: icons.grocieries.salumi,
  type: EFilterType.food,
};

/**
 * Filtri per i acquisti
 */
const artigianato: IFilterItem = {
  id: StoreSubcategoryEnum.Artigianato,
  title: 'artigianato',
  icon: icons.shopping.artigianato,
  type: EFilterType.food,
};
const bodycare: IFilterItem = {
  id: StoreSubcategoryEnum.Bodycare,
  title: 'cura_del_corpo',
  icon: icons.shopping.bodycare,
  type: EFilterType.food,
};
const fiori: IFilterItem = {
  id: StoreSubcategoryEnum.Fiori,
  title: 'fiori',
  icon: icons.shopping.fiori,
  type: EFilterType.food,
};
const petcare: IFilterItem = {
  id: StoreSubcategoryEnum.Petcare,
  title: 'prodotti_per_animali',
  icon: icons.shopping.petcare,
  type: EFilterType.food,
};
const utilities: IFilterItem = {
  id: StoreSubcategoryEnum.Utilities,
  title: 'servizi',
  icon: icons.shopping.utilities,
  type: EFilterType.food,
};

/**
 * Logistica
 */

// const deposit: IFilterItem = {
//   id: StoreSubcategoryEnum.Deposit,
//   title: 'depositi',
//   name: EBigIconButtonType.dipositi,

//   icon: icons.logistics.deposit,
//   type: EFilterType.other,
// };
const events: IFilterItem = {
  id: StoreSubcategoryEnum.Events,
  title: '🎉  eventi',
  name: EBigIconButtonType.eventi,

  icon: icons.logistics.event,
  type: EFilterType.other,
};
const pills: IFilterItem = {
  id: StoreSubcategoryEnum.Pills,
  title: '💎 Gems',
  name: EBigIconButtonType.pills,

  icon: icons.logistics.pill,
  type: EFilterType.other,
};
const tavoliPrenotabili: IFilterItem = {
  id: 101,
  title: 'Tavoli Prenotabili',
  icon: icons.logistics.table_b,
  type: EFilterType.other,
  customFilter: async (data, extra) => {
    return true;
  },
};
const worldWide: IFilterItem = {
  id: 102,
  title: 'WorldWide Store',
  icon: icons.logistics.worldWide_b,
  type: EFilterType.other,
  customFilter: async (data, extra) => {
    return data.world_wide_order ?? false;
  },
};
// const h2O: IFilterItem = {
//   id: StoreSubcategoryEnum.Fountain,
//   title: 'H2O',
//   name: EBigIconButtonType.H20,
//   icon: icons.logistics.fountain,
//   type: EFilterType.other,
// };
const aroundMe: IFilterItem = {
  id: 104,
  title: 'Around Me',
  icon: icons.logistics.around_me,
  type: EFilterType.other,
  customFilter: async (data, extra) => {
    let distance = 0;
    if (extra?.location) {
      distance = getDistance(
        extra.location.lat,
        extra.location.lng,
        data.latitude,
        data.longitude,
      );
    }

    return distance <= (extra.range ?? 300);
  },
};

const tableBooking: IFilterItem = {
  id: 105,
  title: '🍽️ Booking',
  name: EBigIconButtonType.tableBooking,
  icon: icons.logistics.table,
  type: EFilterType.other,
};
const experience: IFilterItem = {
  id: StoreSubcategoryEnum.Experience,
  title: '🎭  experience',
  name: EBigIconButtonType.experience,
  icon: icons.experience,
  type: EFilterType.other,
};

/**
 * Altri luoghi
 */
const church: IFilterItem = {
  id: OtherFiltersEnum.Church,
  title: 'chiesa',
  icon: icons.church,
  type: EFilterType.other,
};
const ferry: IFilterItem = {
  id: OtherFiltersEnum.Ferry,
  title: 'traghetto',
  icon: icons.ferry,
  type: EFilterType.other,
};
const fountain: IFilterItem = {
  id: OtherFiltersEnum.Fountain,
  title: 'fontana',
  icon: icons.fountain,
  type: EFilterType.other,
};
const gondolier: IFilterItem = {
  id: OtherFiltersEnum.Gondolier,
  title: 'gondole',
  icon: icons.gondolier,
  type: EFilterType.other,
};
const museum: IFilterItem = {
  id: OtherFiltersEnum.Museum,
  title: 'museo',
  icon: icons.museum,
  type: EFilterType.other,
};
const park: IFilterItem = {
  id: OtherFiltersEnum.Park,
  title: 'parco',
  icon: icons.park,
  type: EFilterType.other,
};
const pickup: IFilterItem = {
  id: OtherFiltersEnum.Pickup,
  title: 'pickup',
  icon: icons.pickup,
  type: EFilterType.other,
};
const public_place: IFilterItem = {
  id: OtherFiltersEnum.PublicPlace,
  title: 'locali',
  icon: icons.public_place,
  type: EFilterType.other,
};
const wc: IFilterItem = {
  id: OtherFiltersEnum.WC,
  title: 'wc',
  icon: icons.wc,
  type: EFilterType.other,
};

/**
 * Altri filtri
 */
const favourite: IFilterItem = {
  id: OtherFiltersEnum.Favourite,
  title: 'preferiti',
  icon: icons.favourite_black,
  type: EFilterType.other,
};
const bests_voted: IFilterItem = {
  id: OtherFiltersEnum.BestsVoted,
  title: 'piu_votati',
  icon: icons.star,
  type: EFilterType.other,
};

//normals
const smart_logistic: IFilterItem = {
  id: 'smart_logistic',
  name: EBigIconButtonType.luggageDelivery,
  title: '🧳 Luggage',
  icon: bigIconsBottomSheet.smart_logistic.imageUrl,
  type: EFilterType.other,
};

const onDemand: IFilterItem = {
  id: 'onDemand',
  name: EBigIconButtonType.onDemand,
  title: '📦 Spedizioni',
  icon: bigIconsBottomSheet.onDemand.imageUrl,
  type: EFilterType.other,
};
const Botteghe: IFilterItem = {
  id: 'acquisti',
  name: EBigIconButtonType.botteghe,
  title: '🎨 Botteghe',
  icon: bigIconsBottomSheet.Botteghe.imageUrl,
  type: EFilterType.other,
};
const lightCatering: IFilterItem = {
  id: 'lightCatering',
  title: '🥂 Catering',
  name: EBigIconButtonType.lightCatering,
  icon: bigIconsBottomSheet.lightCatering.imageUrl,
  type: EFilterType.other,
};
const bevande: IFilterItem = {
  id: 'bevande',
  title: '🥖 spesa',
  name: EBigIconButtonType.bevande,
  icon: bigIconsBottomSheet.bevande.imageUrl,
  type: EFilterType.other,
};
const cibo: IFilterItem = {
  id: 'cibo',
  name: EBigIconButtonType.cibo,
  title: '🍟 cibo',
  icon: bigIconsBottomSheet.cibo.imageUrl,
  type: EFilterType.other,
};

const completeList = [
  // Cibi
  asiatico,
  burgers,
  gastronomia,
  mediterraneo,
  sushi,
  poke,
  pizza,
  carne,
  dolciegelati,
  // Bevande
  birra,
  cocktail,
  cocktailkit,
  liquoriedistillati,
  softdrink,
  vino,
  // Spesa
  aromiprofumi,
  macelleria,
  ortofrutta,
  panificati,
  pescheria,
  prodotticaseari,
  salumi,
  // Acquisti
  artigianato,
  bodycare,
  fiori,
  petcare,
  utilities,
  //Logistica
  // deposit,
  events,
  pills,
  tavoliPrenotabili,
  worldWide,
  // h2O,
  aroundMe,
  tableBooking,
  // Altri luoghi
  church,
  ferry,
  fountain,
  gondolier,
  museum,
  park,
  pickup,
  public_place,
  wc,
  // Altri filtri
  favourite,
  bests_voted,
  experience,
  // normals
  smart_logistic,

  onDemand,
  Botteghe,
  lightCatering,
  bevande,
  cibo,
];

export default {
  // Cibi
  asiatico,
  burgers,
  gastronomia,
  mediterraneo,
  sushi,
  poke,
  pizza,
  carne,
  dolciegelati,
  // Bevande
  birra,
  cocktail,
  cocktailkit,
  liquoriedistillati,
  softdrink,
  vino,
  // Spesa
  aromiprofumi,
  macelleria,
  ortofrutta,
  panificati,
  pescheria,
  prodotticaseari,
  salumi,
  // Acquisti
  artigianato,
  bodycare,
  fiori,
  petcare,
  utilities,
  //Logistica
  // deposit,
  events,
  pills,
  tavoliPrenotabili,
  worldWide,
  // h2O,
  aroundMe,
  tableBooking,
  // Altri luoghi
  church,
  ferry,
  fountain,
  gondolier,
  museum,
  park,
  pickup,
  public_place,
  wc,
  // Altri filtri
  favourite,
  bests_voted,
  // Lista completa
  completeList,
  experience,

  // normals
  smart_logistic,

  onDemand,
  Botteghe,
  lightCatering,
  bevande,
  cibo,
};
