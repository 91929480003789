import React, { useState, useEffect } from 'react';

function LocationPin({
  size = 20,
  icon = './images/icons/pin/navigating/location.png',
}) {
  return <img src={icon} height={size} />;
}

export default React.memo(LocationPin);
