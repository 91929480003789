export enum TableBookingStatus {
  NUOVO = 'nuovo',
  ACCETTATO_DAL_RISTORANTE = 'confermato',
  ARRIVATO = 'arrivato',
  ANNULLATO = 'annullato',
  NO_SHOW = 'no-show',
  RIFIUTATO_DA_COCAI = 'rifiutato da cocai',
  RIFIUTATO_DAL_RISTORANTE = 'rifiutato dal ristorante',
  SEGNALAZIONE = 'ST001',

  ERROR_NO_SHOW_CODE = 'ST001',
}
