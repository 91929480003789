import { AuthProvider, HttpError } from '@pankod/refine-core';
import axios, { AxiosInstance } from 'axios';
import { User } from 'interfaces';
import { UserRoleEnum } from '../types/users/userRole';
import UserHandler from 'util/UserHandler';

export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const USER_AUTH = 'user-auth';

interface Login {
  email: string;
  password: string;
}

interface UserData {
  data: {
    accessToken: string;
    user: User;
  };
  headers: {
    refreshtoken: string;
  };
}

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API}/user`,
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error);
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };
    return Promise.reject(customError);
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const responseErrorHandler = async (error: any) => {
  // Funzione richimata all'errore di una chiamata al server, in caso sia un 401 tenta il recupero del token
  // Controllo del numero di errore
  console.log(error);
  if (error.status === 401) {
    const userHandler = UserHandler.getInstance();
    // Recupero del refresh token da local storage
    const refreshtoken = userHandler.refreshToken;
    if (refreshtoken) {
      // Chiamata al servizio per ottenere un nuovo token
      const httpClient: AxiosInstance = axiosInstance;
      // Authorization contiene il refresh token recuperato da local storage
      const options = {
        headers: {
          Authorization: `Bearer ${refreshtoken}`,
        },
      };
      const { data } = await httpClient.get<Login, UserData>('/token', options);
      // Se la chiamata è andata a buon fine salviamo il nuovo token in local storage
      if (data.accessToken) {
        userHandler.token = data.accessToken;
      }
    }
  }
  return Promise.resolve();
};

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    if (!email || !password) {
      Promise.reject(new Error('Username o password non corretti'));
    }
    // Login chiama il server e recupera token, refreshtoken e user
    const httpClient: AxiosInstance = axiosInstance;
    const { data, headers } = await httpClient.post<Login, UserData>(`/login`, {
      email,
      password,
    });
    if (data.accessToken && headers.refreshtoken && data.user) {
      if (UserRoleEnum.User === data.user.role) {
        // Salva i dati recuperati in local storage
        const userHandler = UserHandler.getInstance();
        userHandler.token = data.accessToken;
        userHandler.refreshToken = headers.refreshtoken;
        userHandler.user = data.user;
        userHandler.ghostToken = '';
        return Promise.resolve('/home');
      }
    }
    return Promise.reject(new Error('Username o password non corretti'));
  },
  logout: () => {
    // Rimozione di tutti gli oggetti salvati in local storage
    UserHandler.clear();
    return Promise.resolve('/login');
  },
  checkError: async error => {
    // Rimando alla pagina di default (login)
    if (error?.response?.data?.statusCode === 404) {
      return Promise.resolve();
    }
    return Promise.reject(error);
  },
  checkAuth: () => {
    // Controlliamo la presenza del token
    return Promise.resolve();
  },
  getPermissions: () => {
    // Recupera il ruolo dell'utente loggato
    const role = UserHandler.getInstance().role;
    if (!role) {
      return Promise.reject();
    }
    return Promise.resolve(role);
  },
  getUserIdentity: async () => {
    // Recupera i dati dell'utente salvati in local storage
    const userHandler = UserHandler.getInstance();
    const token = userHandler.token;
    const user = userHandler.user;
    if (!token || !user) {
      return Promise.resolve(undefined);
    }
    return Promise.resolve(user);
  },
};
