import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigation, useTranslate } from '@pankod/refine-core';
import icons from '../../constants/icons';
import './styles.css';
import { StatusName } from '../../types/orders/statusNameEnum';
import { TableBookingStatus } from '../../types/orders/tableBookingStatus';
import { DateTime } from 'luxon';

interface IButtonNavigator {
  replaceOpt?: boolean;
  title: string | ReactElement | HTMLElement;
  navigate?: string;
  iconUrl: string;
  danger?: boolean;
  alt: string;
  icon_suffix: 'arrow' | 'info';
  callback?: () => void;
  order: any;
  fromHome?: boolean;
}

export const ButtonNavigator: React.FC<IButtonNavigator> = ({
  title,
  navigate,
  iconUrl,
  alt,
  icon_suffix,
  callback,
  danger = false,
  replaceOpt = false,
  order,
  fromHome,
}) => {
  const { push, replace } = useNavigation();
  const t = useTranslate();
  const [isNotConsegnato, setisNotConsegnato] = useState(false);

  useEffect(() => {
    if (
      order?.status?.name !== 'consegnato' &&
      order?.status?.name !== StatusName.ANNULLATO &&
      order?.status?.name !== StatusName.RIFIUTATO_DAL_RISTORANTE
    ) {
      setisNotConsegnato(true);
    }
  }, [order, isNotConsegnato]);

  const getStatus = (status: any) => {
    if (status.error_code === TableBookingStatus.ERROR_NO_SHOW_CODE) {
      return TableBookingStatus.NO_SHOW;
    } else {
      switch (status.name) {
        case StatusName.NUOVO:
          return StatusName.NUOVO;
        case StatusName.PRENOTATO_DAL_RUNNER:
          return StatusName.NUOVO;

        case StatusName.ACCETTATO_DAL_RISTORANTE:
          return StatusName.ACCETTATO_DAL_RISTORANTE;

        case StatusName.CONFERMATO:
          return StatusName.ACCETTATO_DAL_RISTORANTE;

        case StatusName.IN_PREPARAZIONE:
          return StatusName.ACCETTATO_DAL_RISTORANTE;

        case StatusName.IN_ATTESA_DEL_RISTORANTE:
          return StatusName.ACCETTATO_DAL_RISTORANTE;

        case StatusName.PRONTO:
          return StatusName.ACCETTATO_DAL_RISTORANTE;

        case StatusName.CONSEGNATO:
          return TableBookingStatus.ARRIVATO;

        case StatusName.ANNULLATO:
          return TableBookingStatus.ANNULLATO;

        case StatusName.RIFIUTATO_DAL_RISTORANTE:
          return TableBookingStatus.RIFIUTATO_DAL_RISTORANTE;

        case StatusName.RIFIUTATO_DA_COCAI:
          return TableBookingStatus.RIFIUTATO_DA_COCAI;

        case StatusName.SEGNALAZIONE:
          return TableBookingStatus.SEGNALAZIONE;

        default:
          return status.name;
      }
    }
  };

  return (
    <div
      onClick={() => {
        callback && callback();
        navigate && (replaceOpt ? replace(navigate) : push(navigate));
      }}
      className={`itemContainer ${!icon_suffix ? 'no-border' : ''} ${
        danger ? 'itemContainer--danger' : ''
      }`}>
      {!fromHome && (
        <span>
          <img
            style={{ maxHeight: title === 'Cocai wallet' && '40px' }}
            src={iconUrl}
            alt={alt}
          />
          <div>{title}</div>
        </span>
      )}
      {icon_suffix === 'arrow' && (
        <img src={icons.arrow} alt={t('buttonNavigator.arrow')} />
      )}
      {icon_suffix === 'info' && (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <span
            className={isNotConsegnato ? 'blink' : ''}
            style={{
              fontWeight: 'bold',
              // whiteSpace: 'nowrap',
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
            }}>
            <span>
              {order.table_orders
                ? getStatus(order.status)
                : t(
                    `orders.status.${
                      order.status?.name === 'pronto' &&
                      order.food_orders &&
                      DateTime.fromISO(
                        order.food_orders.real_pickup_time.replace('Z', ''),
                        { zone: 'Europe/Rome' },
                      ) > DateTime.now()
                        ? 'in_preparazione'
                        : order.status?.name.replaceAll(' ', '_')
                    }`,
                  )}
            </span>
          </span>
          {!fromHome && (
            <img src={icons.info} alt={t('buttonNavigator.info')} />
          )}
        </div>
      )}
    </div>
  );
};
