import './styles.css';

export enum EFilterType {
  food = 1,
  other = 0,
}

export interface IFilterItem {
  id: any;
  icon?: string;
  name?: any;
  title: string;
  type: EFilterType;
  customFilter?: (data: any, extra?: any) => Promise<boolean>;
}

interface IFilterItemExt extends IFilterItem {
  dark?: boolean;
  transparent?: boolean;
  onClick: (id: number) => void;
  noInvert?: boolean;
}

export const FilterItem: React.FC<IFilterItemExt> = ({
  id,
  icon,
  title,
  dark,
  transparent,
  onClick,
  noInvert,
}) => {
  return (
    <div
      className={`filter ${dark ? 'filter--dark' : ''} ${
        transparent ? 'filter--transparent' : ''
      }`}
      onClick={() => onClick(id)}>
      {icon && (
        <img
          src={icon}
          alt={title}
          style={{ filter: noInvert ? 'none' : '' }}
          className="filter__icon"
        />
      )}
      <p className="filter__title">{title}</p>
    </div>
  );
};
