import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useLogout,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import { Modal } from '@pankod/refine-antd';
import { ButtonNavigator } from '../../components/buttonNavigator';
import { NavBar } from '../../components/navBar';
import icons from '../../constants/icons';
import navBarIcons from '../../constants/navBarIcons';
import UserHandler from '../../util/UserHandler';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Loading } from '../loading';

import './styles.css';
import { useDispatch } from 'react-redux';
import { addorderData } from '../../store/smartLogOrders';
import { OrderPaymentMethodEnum } from '../../types/orderPaymentMethodEnum';

export const Profile: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();
  const { mutate: logout } = useLogout();
  const url = useApiUrl();
  const dispatch = useDispatch();
  const logoutUser = useCallback(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(`{"logout": "true" }`);
    }
    dispatch(
      addorderData({
        store_id: 0,
        user_id: 0,
        date: undefined,
        payment_method: OrderPaymentMethodEnum.CreditCard,
        total: 0,
        custom_discount: 0,
        note: null,
        number: '',
        //orderType: null,
        //order_type: null,
        food_orders: null,
        smart_logistics_orders: null,
        table_orders: null,
        extra_info: null,
      }),
    );

    logout();
  }, []);

  const deleteUser = useCallback(() => {
    const confirmDelete = async (id: number) => {
      const token = UserHandler.getInstance().token;
      const payload = { user: UserHandler.getInstance().user };
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const result = await axios
        .patch(`${url}/user/${id}/delete`, payload, { headers })
        .then(res => res)
        .catch(err => err);
      if (result.status !== 200) {
        Modal.error({
          title: t('profile.errModal.title'),
          content: t('profile.errModal.content'),
        });
      } else {
        Modal.success({
          title: t('profile.successModal.title'),
          content: t('profile.successModal.content'),
          afterClose: () => {
            logoutUser();
          },
        });
      }
    };
    Modal.confirm({
      title: t('profile.removeAccountModal.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('profile.removeAccountModal.content'),
      okText: t('profile.removeAccountModal.okText'),
      okType: 'danger',
      cancelText: t('profile.removeAccountModal.cancelText'),
      onOk: () => confirmDelete(user.id),
    });
  }, []);

  useEffect(() => {
    if (!user) {
      push('/login');
    }
  }, [user]);

  if (!user) {
    return <Loading />;
  }

  return (
    <div className="profile">
      <div className="profile__navbar">
        <NavBar back={true} onBack={() => push('/home')} />
      </div>
      <div className="profile__mainHeader">
        <img src={navBarIcons.avatar} />
      </div>
      <div className="profile__mainContainer">
        <div className="profile__mainContainer__header">
          <h1>
            {user.name} {user.lastname}
          </h1>
          <p>{user.email}</p>
        </div>
        <div className="profile__mainContainer__body">
          <ButtonNavigator
            title={t('profile.myorders')}
            navigate="/profile/orders"
            iconUrl={icons.profile.box}
            alt={t('profile.myorders')}
            icon_suffix="arrow"
          />
          <ButtonNavigator
            title={t('profile.myinfo')}
            navigate="/profile/infos"
            iconUrl={icons.profile.briccola}
            alt={t('profile.myinfo')}
            icon_suffix="arrow"
          />
          <ButtonNavigator
            replaceOpt={true}
            title={t('profile.myaddress')}
            navigate="/profile/addresses"
            iconUrl={icons.profile.location}
            alt={t('profile.myaddress')}
            icon_suffix="arrow"
          />
          <ButtonNavigator
            title={t('Cocai wallet')}
            callback={() => push('/wallet')}
            iconUrl={icons.profile.wallet}
            alt={t('profile.logout')}
            icon_suffix="arrow"
          />
          <ButtonNavigator
            title={t('profile.faq')}
            navigate="/faq"
            iconUrl={icons.profile.question}
            alt={t('profile.faq')}
            icon_suffix="arrow"
          />
          <ButtonNavigator
            title={t('profile.logout')}
            callback={logoutUser}
            iconUrl={icons.profile.logout}
            alt={t('profile.logout')}
            icon_suffix="arrow"
          />
          <ButtonNavigator
            title={t('profile.deleteAccount')}
            iconUrl={icons.profile.remove}
            alt={t('profile.deleteAccount')}
            danger={true}
            icon_suffix="arrow"
            callback={deleteUser}
          />
        </div>
      </div>
    </div>
  );
};
