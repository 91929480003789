import { Store } from 'interfaces';
import { DateTime } from 'luxon';

const useIsClosed = () => {
  const isClosed = (store: Store) => {
    const today = DateTime.now().setZone('Europe/Rome');
    const openings = store.opening_times;
    const closures = store.store_closures;
    let openNow = false;
    if (openings && openings.length > 0) {
      openings
        .filter(item => item.day === today.weekday % 7)
        .forEach(todayOpening => {
          if (!todayOpening.start_time || !todayOpening.end_time) {
            return;
          }
          const start_time = DateTime.fromISO(
            todayOpening.start_time.replace('Z', ''),
            { zone: 'Europe/Rome' },
          );
          let end_time = DateTime.fromISO(
            todayOpening.end_time.replace('Z', ''),
            { zone: 'Europe/Rome' },
          );
          if (end_time.hour === 0 && end_time.minute === 0) {
            end_time = end_time.minus({ minutes: 1 });
          }
          let openFlag =
            start_time.hour <= today.hour && end_time.hour >= today.hour;
          if (start_time.hour === today.hour || end_time.hour === today.hour) {
            if (start_time.hour === today.hour) {
              openFlag = start_time.minute <= today.minute;
            } else if (end_time.hour === today.hour) {
              openFlag = end_time.minute > today.minute;
            }
          }
          openNow = openFlag || openNow;
        });
    }

    if (openNow && closures && closures.length > 0) {
      closures.forEach(closure => {
        if (!closure.from || !closure.to) {
          return;
        }

        const fromDate = DateTime.fromISO(closure.from.replace('Z', ''), {
          zone: 'Europe/Rome',
        });
        const toDate = DateTime.fromISO(closure.to.replace('Z', ''), {
          zone: 'Europe/Rome',
        });

        if (today >= fromDate && today <= toDate) {
          openNow = false;
        }
      });
    }

    // The 'openNow' variable now correctly reflects whether the restaurant is open or closed.

    // if (openNow && closures && closures.length > 0) {
    //   closures.forEach(closure => {
    //     if (!closure.from || !closure.to) {
    //       return;
    //     }
    //     const fromDate = DateTime.fromISO(closure.from.replace('Z', ''), {
    //       zone: 'Europe/Rome',
    //     });
    //     const toDate = DateTime.fromISO(closure.to.replace('Z', ''), {
    //       zone: 'Europe/Rome',
    //     });
    //     if (fromDate.hour <= today.hour && toDate.hour >= today.hour) {
    //       if (fromDate.hour === today.hour || toDate.hour === today.hour) {
    //         if (fromDate.hour === today.hour) {
    //           openNow = fromDate.minute >= today.minute;
    //           if (toDate.hour === today.hour) {
    //             openNow = toDate.minute < today.minute;
    //           }
    //         } else if (toDate.hour === today.hour) {
    //           openNow = toDate.minute < today.minute;
    //         }
    //       } else {
    //         if (fromDate.hour < today.hour && toDate.hour > today.hour) {
    //           openNow = true;
    //         } else {
    //           openNow = false;

    //         }
    //       }
    //     }
    //   });
    // }
    return !openNow;
  };

  return isClosed;
};

export default useIsClosed;
