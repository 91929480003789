import { createReducer } from '@reduxjs/toolkit';
import { Favourite } from 'interfaces';
import { addstore, init, removestore } from './favourites.actions';

const favouritesInitialState: Favourite[] = [];

export const favouritesReducer = createReducer(
  favouritesInitialState,
  builder =>
    builder
      .addCase(init, (state, action) => action.payload)
      .addCase(addstore, (state, action) => [...state, action.payload])
      .addCase(removestore, (state, action) =>
        state.filter(
          favourite => favourite.store_id !== action.payload.store_id,
        ),
      ),
);
