const SET_USER_LOCATION = 'SET_USER_LOCATION';
const CLEAR_USER_LOCATION = 'CLEAR_USER_LOCATION';

export const setUserLocation = (latitude: any, longitude: any) => ({
  type: SET_USER_LOCATION,
  payload: { latitude, longitude },
});

export const clearUserLocation = () => ({
  type: CLEAR_USER_LOCATION,
});

const initialState = {
  latitude: 45.434425,
  longitude: 12.339164,
};

const userlocationReducer = (
  state = initialState,
  action: { type: any; payload: { latitude: any; longitude: any } },
) => {
  switch (action.type) {
    case SET_USER_LOCATION:
      return {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    case CLEAR_USER_LOCATION:
      return initialState;
    default:
      return state;
  }
};

export default userlocationReducer;
