export default {
  cibo: {
    imageUrl: process.env.PUBLIC_URL + '/images/navigationIcons/food.png',
    alt: 'bigIcons.cibo',
  },
  bevande: {
    imageUrl: process.env.PUBLIC_URL + '/images/navigationIcons/bevande.png',
    alt: 'bigIcons.bevande',
  },
  lightCatering: {
    imageUrl: process.env.PUBLIC_URL + '/images/navigationIcons/catering.png',

    alt: 'bigIcons.lightCatering',
  },
  Botteghe: {
    imageUrl: process.env.PUBLIC_URL + '/images/navigationIcons/botteghe.png',

    alt: 'bigIcons.Botteghe',
  },
  onDemand: {
    imageUrl: process.env.PUBLIC_URL + '/images/navigationIcons/ondemand.png',
    alt: 'bigIcons.onDemand',
  },
  tableBooking: {
    imageUrl:
      process.env.PUBLIC_URL + '/images/navigationIcons/tableBooking.svg',
    alt: 'bigIcons.tableBooking',
  },
  otherIcons: {
    deposit:
      process.env.PUBLIC_URL + '/images/icons/logistics/deposit_giallo.png',
    deposit_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/deposit_blu.png',
    fountain:
      process.env.PUBLIC_URL + '/images/icons/logistics/fountain_giallo.png',
    fountain_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/fountain_blu.png',
    event: process.env.PUBLIC_URL + '/images/icons/logistics/event_giallo.png',
    event_b: process.env.PUBLIC_URL + '/images/icons/logistics/event_blu.png',
    pill: process.env.PUBLIC_URL + '/images/icons/logistics/pill_giallo.png',
    pill_b: process.env.PUBLIC_URL + '/images/icons/logistics/pill_blu.png',
    worldWide:
      process.env.PUBLIC_URL + '/images/icons/logistics/worldWide_bianco.png',
    worldWide_b:
      process.env.PUBLIC_URL + '/images/icons/logistics/worldWide_blu.png',
    table: process.env.PUBLIC_URL + '/images/icons/logistics/table_bianco.png',
    table_b: process.env.PUBLIC_URL + '/images/icons/logistics/table_blu.png',
    around_me: process.env.PUBLIC_URL + '/images/icons/logistics/around_me.png',
    experience: process.env.PUBLIC_URL + '/images/icons/experienceBianco.png',
  },
  emojies: {
    cibo: process.env.PUBLIC_URL + '/images/icons/radialMenu/Cibo.png',
    table: process.env.PUBLIC_URL + '/images/icons/radialMenu/TableBooking.png',
    bothege: process.env.PUBLIC_URL + '/images/icons/radialMenu/Botteghe.png',
    event: process.env.PUBLIC_URL + '/images/icons/radialMenu/Catering.png',

    catering: process.env.PUBLIC_URL + '/images/icons/radialMenu/Eventi.png',
    experience:
      process.env.PUBLIC_URL + '/images/icons/radialMenu/Experiences.png',
    Gems: process.env.PUBLIC_URL + '/images/icons/radialMenu/Gems.png',
    spedizione:
      process.env.PUBLIC_URL + '/images/icons/radialMenu/Spedizioni.png',
    spesa: process.env.PUBLIC_URL + '/images/icons/radialMenu/Spesa.png',
    luggage: process.env.PUBLIC_URL + '/images/icons/radialMenu/luggage.png',
  },
  smart_logistic: {
    imageUrl:
      process.env.PUBLIC_URL +
      '/images/navigationIcons/luggagedelivery_icona.svg',
    alt: 'bigIcons.smart_logistic',
  },
};
