import { UserRoleEnum } from 'types/users/userRole';
import { User } from '../interfaces';

export const GHOST_TOKEN_KEY = 'webapp-ghost-token';
export const TOKEN_KEY = 'webapp-token';
export const REFRESH_TOKEN_KEY = 'webapp-refresh-token';
export const USER_AUTH = 'webapp-user-auth';

export default class UserHandler {
  private static instance: UserHandler | undefined;

  private constructor() {
    console.log('UserHandler init...');
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new UserHandler();
    }
    return this.instance;
  }

  public static clear() {
    localStorage.removeItem(GHOST_TOKEN_KEY);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_AUTH);
  }

  public get ghostToken(): string {
    return localStorage.getItem(GHOST_TOKEN_KEY) ?? '';
  }

  public set ghostToken(value: string) {
    localStorage.setItem(GHOST_TOKEN_KEY, value);
  }

  public get token(): string {
    return localStorage.getItem(TOKEN_KEY) ?? '';
  }

  public set token(value: string) {
    localStorage.setItem(TOKEN_KEY, value);
  }

  public get refreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_KEY) ?? '';
  }

  public set refreshToken(value: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, value);
  }

  public get user(): User {
    return localStorage.getItem(USER_AUTH)
      ? JSON.parse(localStorage.getItem(USER_AUTH) ?? '')
      : '';
  }

  public set user(value: User) {
    localStorage.setItem(USER_AUTH, JSON.stringify(value));
  }

  public get role(): string {
    return this.user.role;
  }

  public static loginSocial(data: {
    accessToken: string;
    refreshToken: string;
    user: User;
  }) {
    if (data.accessToken && data.refreshToken && data.user) {
      if (UserRoleEnum.User === data.user.role) {
        // Salva i dati recuperati in local storage
        const userHandler = UserHandler.getInstance();
        userHandler.refreshToken = data.refreshToken;
        userHandler.token = data.accessToken;
        userHandler.user = data.user;
        if (!userHandler.user.phone || userHandler.user.phone === '') {
          return Promise.resolve('/profile/infos');
        } else {
          return Promise.resolve('/home');
        }
      }
    }
    return Promise.reject(new Error('Utente non valido'));
  }
}
