import { createReducer } from '@reduxjs/toolkit';
import { popPersistent, pushPersistent } from './persistent.actions';

const persistentInitialState = null;

export const persistentReducer = createReducer(
  persistentInitialState,
  builder =>
    builder
      .addCase(popPersistent, () => persistentInitialState)
      .addCase(pushPersistent, (_state, action) => action.payload),
);
