import React, { Suspense, useContext, useEffect } from 'react';
import { Refine, PromptProps, useNavigation } from '@pankod/refine-core';
import { notificationProvider, ErrorComponent } from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';
import { authProvider } from './services/authProvider';
import { useTranslation } from 'react-i18next';
import ApiProvider from './services/ApiClass';
import accessControlProvider from './services/accessControlProvider';
import packageJson from '../package.json';

const Login = React.lazy(() =>
  import('./pages/login').then(module => ({ default: module.Login })),
);
const Home = React.lazy(() =>
  import('./pages/home').then(module => ({ default: module.Home })),
);
const ReadyPage = React.lazy(() =>
  import('./pages/readyPage').then(module => ({ default: module.ReadyPage })),
);

const StoreList = React.lazy(() =>
  import('./pages/storeList').then(module => ({ default: module.StoreList })),
);
const StorePage = React.lazy(() =>
  import('./pages/store').then(module => ({ default: module.StorePage })),
);
// const Profile = React.lazy(() =>
//   import('./pages/profile').then(module => ({ default: module.Profile })),
// );
const SignUp = React.lazy(() =>
  import('./pages/signup').then(module => ({ default: module.SignUp })),
);
const ProductPage = React.lazy(() =>
  import('./pages/product').then(module => ({ default: module.ProductPage })),
);
const UserInfos = React.lazy(() =>
  import('./pages/profile/infos').then(module => ({
    default: module.UserInfos,
  })),
);
const UserOrders = React.lazy(() =>
  import('./pages/profile/orders').then(module => ({
    default: module.UserOrders,
  })),
);
const UserAddresses = React.lazy(() =>
  import('./pages/profile/addresses').then(module => ({
    default: module.UserAddresses,
  })),
);
const Faq = React.lazy(() =>
  import('./pages/faq').then(module => ({ default: module.Faq })),
);
const Payment = React.lazy(() =>
  import('./pages/payment').then(module => ({ default: module.Payment })),
);
const CartPage = React.lazy(() =>
  import('./pages/cartPage').then(module => ({ default: module.CartPage })),
);
const UserOrderDetails = React.lazy(() =>
  import('./pages/profile/order_details').then(module => ({
    default: module.UserOrderDetails,
  })),
);
const NewAddress = React.lazy(() =>
  import('./pages/profile/new_address').then(module => ({
    default: module.NewAddress,
  })),
);
const EditAddress = React.lazy(() =>
  import('./pages/profile/edit_address').then(module => ({
    default: module.EditAddress,
  })),
);
const MapAddressPage = React.lazy(() =>
  import('./pages/mapAddressPage').then(module => ({
    default: module.MapAddressPage,
  })),
);
const PaymentMethod = React.lazy(() =>
  import('./pages/paymentMethod').then(module => ({
    default: module.PaymentMethod,
  })),
);
const GhostUser = React.lazy(() =>
  import('./pages/ghostUser').then(module => ({ default: module.GhostUser })),
);

const Checkout = React.lazy(() =>
  import('./pages/checkout').then(module => ({ default: module.Checkout })),
);
const PasswordReset = React.lazy(() =>
  import('./pages/passwordReset').then(module => ({
    default: module.PasswordReset,
  })),
);
const PasswordChange = React.lazy(() =>
  import('./pages/passwordChange').then(module => ({
    default: module.PasswordChange,
  })),
);
const AddressPage = React.lazy(() =>
  import('./pages/addressPage').then(module => ({
    default: module.AddressPage,
  })),
);
const Privacy = React.lazy(() =>
  import('./pages/privacy').then(module => ({ default: module.Privacy })),
);
const MissingUser = React.lazy(() =>
  import('./pages/missingUser').then(module => ({
    default: module.MissingUser,
  })),
);
const OrderReviews = React.lazy(() =>
  import('./pages/profile/orderReviews').then(module => ({
    default: module.OrderReviews,
  })),
);
import * as StoreActionsRedux from './store/notifications/notifications.actions';
import { useDispatch } from 'react-redux';
import { MapBox, EMapType, MapEventType } from './components/mapBox';
import { Coords } from './interfaces';

import {
  BrowserRouter as Router,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
const On_Demand = React.lazy(() =>
  import('./pages/on_Demand').then(module => ({ default: module.On_Demand })),
);
const OnDemand = React.lazy(() =>
  import('./pages/luggage').then(module => ({ default: module.OnDemand })),
);
const Privacy_policy = React.lazy(() => import('./pages/privacy_policy_apps'));
const AddressChooserFrom = React.lazy(() =>
  import('./pages/smart_logistic/addressChooserFrom').then(module => ({
    default: module.AddressChooserFrom,
  })),
);
const AddressChooserTo = React.lazy(() =>
  import('./pages/smart_logistic/adressChooserTo').then(module => ({
    default: module.AddressChooserTo,
  })),
);
import { Profile } from './pages/profile';
// const Light_cathering = React.lazy(() => import('./pages/lightCathering'));
import Light_cathering from './pages/lightCathering';
const Login_page = React.lazy(() =>
  import('./pages/loginPage').then(module => ({ default: module.Login_page })),
);
import { EBigIconButtonType } from './components/bigIconButton';
const TrackAllOrders = React.lazy(() => import('./pages/trackOrders'));

import './index.css';

import { PhoneLogin } from './pages/phoneLogin';
import Wallet from './pages/profile/wallet';

const Prompt: React.FC<PromptProps> = ({ message, when, setWarnWhen }) => {
  const navigator = useContext(NavigationContext).navigator as History;
  useEffect(() => {
    const docMap = document.getElementById('map');
    const docRefine = document.getElementById('refine');
    const pathnames = navigator.location.pathname.split('/');
    if (
      !pathnames[pathnames.length - 1] ||
      ['webapp', 'home', ''].includes(pathnames[pathnames.length - 1])
    ) {
      docMap.style.display = 'block';
      docRefine.style['margin-top'] = '-100vh';
    } else {
      docMap.style.display = 'none';
      docRefine.style['margin-top'] = '0vh';
    }
    return null;
  }, [navigator.location]);

  return null;
};

import 'styles/antd.less';
import Support from './pages/support';

if (process.env.REACT_APP_SERVER === 'https://www.cocaiexpress.com/api') {
  console.log = function () {
    return;
  };
}

function App() {
  const { t, i18n } = useTranslation();
  const dispatchRedux = useDispatch();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { RouterComponent } = routerProvider;
  const CustomRouterComponent = () => (
    <RouterComponent basename={packageJson.homepage} />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOrderStatus = (event: any) => {
    const order_status = event.data.order_status;
    if (order_status) {
      dispatchRedux(StoreActionsRedux.pushNotifications(order_status));
    }
  };

  useEffect(() => {
    const lang = navigator.language.split('-')[0].toLowerCase();
    i18n.changeLanguage(lang);
    window.addEventListener('message', handleOrderStatus);
    return () => {
      window.removeEventListener('message', handleOrderStatus);
    };
  }, []);
  return [
    <div key={1} id="map" style={{ height: '100vh' }}>
      <div id="recaptcha-container"></div>
      <Router>
        <MapBox showDefaultMarkers={true} home={true} />
      </Router>
    </div>,
    <div key={2} id="refine">
      <Suspense fallback={<div>Loading...</div>}>
        <Refine
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          routerProvider={{
            ...routerProvider,
            Prompt,
            RouterComponent: CustomRouterComponent,
            routes: [
              {
                element: <ReadyPage />,
                path: 'ready',
                layout: true,
              },

              {
                element: <Login />,
                path: 'login',
                layout: true,
              },
              {
                element: <Login_page />,
                path: 'loginPage',
                layout: true,
              },
              {
                element: <Home />,
                path: 'home',
                layout: true,
              },
              {
                element: <StoreList />,
                path: 'stores',
                layout: true,
              },
              {
                element: <StorePage />,
                path: 'stores/:id',
                layout: true,
              },
              {
                element: <ProductPage />,
                path: 'products/:id',
                layout: true,
              },

              {
                element: <Profile />,
                path: 'profile',
                layout: true,
              },
              {
                element: <UserInfos />,
                path: 'profile/infos',
                layout: true,
              },
              {
                element: <UserOrders />,
                path: 'profile/orders',
                layout: true,
              },
              {
                element: <UserOrderDetails />,
                path: 'profile/orders/details/:id',
                layout: true,
              },
              {
                element: <OrderReviews />,
                path: 'profile/orders/details/:id/reviews',
                layout: true,
              },
              {
                element: <UserAddresses />,
                path: 'profile/addresses',
                layout: true,
              },
              {
                element: <NewAddress />,
                path: 'profile/addresses/new',
                layout: true,
              },
              {
                element: <EditAddress />,
                path: 'profile/addresses/edit/:id',
                layout: true,
              },
              {
                element: <Faq />,
                path: 'faq',
                layout: true,
              },
              {
                element: <PaymentMethod />,
                path: 'paymentmethod',
                layout: true,
              },
              {
                element: <Payment />,
                path: 'payment',
                layout: true,
              },
              {
                element: <CartPage />,
                path: 'cart',
                layout: true,
              },
              {
                element: <AddressPage />,
                path: 'addresspage',
                layout: true,
              },
              {
                element: <MapAddressPage />,
                path: 'mapaddress',
                layout: true,
              },
              {
                element: <SignUp />,
                path: 'signup',
                layout: true,
              },
              {
                element: <PasswordReset />,
                path: 'passwordreset',
                layout: true,
              },
              {
                element: <PasswordChange />,
                path: 'passwordchange',
                layout: true,
              },
              {
                element: <MissingUser />,
                path: 'missingUser',
                layout: true,
              },
              {
                element: <GhostUser />,
                path: 'ghostUser',
                layout: true,
              },
              {
                element: <Checkout />,
                path: 'checkout/:id',
                layout: true,
              },
              {
                element: <Privacy />,
                path: 'privacy',
                layout: true,
              },
              {
                element: <OnDemand />,
                path: 'luggageDelivery',
                layout: true,
              },
              {
                element: <On_Demand />,
                path: 'ondemand',
                layout: true,
              },
              {
                element: <AddressChooserFrom />,
                path: 'smartlogistic/addressChooserFrom',
                layout: true,
              },
              {
                element: <AddressChooserTo />,
                path: 'smartlogistic/addressChooserTo',
                layout: true,
              },
              {
                element: <Light_cathering />,
                path: 'light_cathering',
                layout: true,
              },

              {
                element: <Privacy_policy />,
                path: 'PrivacyPolicyApps',
                layout: true,
              },
              {
                element: <TrackAllOrders />,
                path: 'track',
                layout: true,
              },
              {
                element: <PhoneLogin />,
                path: 'phoneLogin',
              },
              {
                element: <Wallet />,
                path: 'wallet',
                layout: true,
              },
              {
                element: <Support />,
                path: 'support',
                layout: true,
              },
            ],
          }}
          dataProvider={ApiProvider(`${process.env.REACT_APP_API}`)}
          authProvider={authProvider}
          accessControlProvider={accessControlProvider}
          DashboardPage={Home}
          LoginPage={Login}
          resources={[
            { name: '', list: ReadyPage, options: { route: '/' } },
            { name: 'ready', list: ReadyPage },
            { name: 'home', list: Home },
          ]}
          i18nProvider={i18nProvider}
        />
      </Suspense>
    </div>,
  ];
}

export default App;
