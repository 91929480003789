export enum UserRoleEnum {
  Admin = 'admin',
  Centralino = 'centralino',
  Runner = 'runner',
  Store = 'store',
  User = 'user',
  Host = 'host',
  Map = 'map',
  Logistic = 'logistic',
}
