import { useTranslate } from '@pankod/refine-core';
import React, { useEffect, useState } from 'react';
import bigIcons from '../../constants/bigIcons';
import './styles.css';
import useIsClosed from 'hooks/useIsClosed';

export enum EBigIconButtonType {
  cibo,
  bevande,
  acquisti,
  botteghe,
  onDemand,
  lightCatering,
  luggageDelivery,
  tableBooking,
  H20,
  eventi,
  pills,
  experience,
  dipositi,
}

export interface IBigIconButton {
  type: EBigIconButtonType;
  title: string;
  onClick: () => void;
  withOpacity?: boolean;
  infoRadial?: any;
  imgSrc?: any;
  store?: any;
}

export const BigIconButton: React.FC<IBigIconButton> = ({
  type,
  title,
  onClick,
  withOpacity = false,
  infoRadial,
  imgSrc,
  store,
}) => {
  const t = useTranslate();
  const isClosed = useIsClosed();
  const [isHovered, setIsHovered] = useState(false);
  const [storeClosed, setStoreClosed] = useState(false);
  let imageUrl = '';
  let alt = '';
  let imgWidth = 61;
  let imgHeight = 61;
  let marginBottom = 10;
  let imgTranslateX = 0;

  switch (type) {
    case EBigIconButtonType.cibo:
      imageUrl = bigIcons.emojies.cibo;
      alt = bigIcons.cibo.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = -3;
      // imgTranslateX = -4;
      break;

    case EBigIconButtonType.tableBooking:
      imageUrl = bigIcons.emojies.table;
      alt = bigIcons.tableBooking.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = 2;
      // imgTranslateX = -4;
      break;
    case EBigIconButtonType.bevande:
      imageUrl = bigIcons.emojies.spesa;

      alt = bigIcons.bevande.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = 2;
      break;
    case EBigIconButtonType.lightCatering:
      imageUrl = bigIcons.emojies.catering;
      alt = bigIcons.lightCatering.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = 2;
      break;
    case EBigIconButtonType.botteghe:
      imageUrl = bigIcons.emojies.bothege;
      alt = bigIcons.Botteghe.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = 2;
      break;
    case EBigIconButtonType.onDemand:
      imageUrl = bigIcons.emojies.spedizione;
      alt = bigIcons.onDemand.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = 2;
      break;

    case EBigIconButtonType.luggageDelivery:
      imageUrl = bigIcons.emojies.luggage;
      alt = bigIcons.smart_logistic.alt;
      imgWidth = 120;
      imgHeight = 54;
      marginBottom = 2;
      imgTranslateX = 2; // translate x

      break;
    case EBigIconButtonType.H20:
      imageUrl = bigIcons.emojies.Gems;
      alt = 'H2O';

      marginBottom = 2;
      imgTranslateX = 2; // translate x

      break;
    case EBigIconButtonType.eventi:
      imageUrl = bigIcons.emojies.event;
      alt = 'event';
      imgWidth = 100;
      imgHeight = 54;
      marginBottom = 2;
      imgTranslateX = 0; // translate x

      break;
    case EBigIconButtonType.experience:
      imageUrl = bigIcons.emojies.experience;
      alt = ' experience';

      marginBottom = 2;
      imgTranslateX = 2; // translate x

      break;

    case EBigIconButtonType.pills:
      imageUrl = bigIcons.emojies.Gems;
      alt = 'pills';
      imgWidth = 100;
      imgHeight = 54;
      marginBottom = 2;
      imgTranslateX = 0; // translate x

      break;
    case EBigIconButtonType.dipositi:
      imageUrl = bigIcons.emojies.spedizione;
      alt = 'dipositi';

      marginBottom = 2;
      imgTranslateX = 2; // translate x

      break;
  }

  useEffect(() => {
    if (store) {
      const isStoreClosed = isClosed(store);
      setStoreClosed(isStoreClosed);
      console.log('isStoreClosed', isStoreClosed);
    }
  }, [store]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={`bigbutton ${withOpacity ? 'with_opacity' : ''}`}>
          <div
            className={`bigbutton__img__container ${
              storeClosed ? 'image-overlay-container' : ''
            }`}
            style={{ marginBottom }}>
            {storeClosed
              ? ''
              : // <span className="store_closed_text">closed</span>
                ''}

            <img
              src={imgSrc ? imgSrc : imageUrl}
              alt={t(alt)}
              className={`${imgSrc ? 'bg_img_' : 'bigbutton__img'}   ${''}`}
              onClick={() => onClick()}
              style={{
                width: imgWidth,
                height: imgHeight,
                transform: `translateX(${imgTranslateX}px)`,
                // objectFit: 'cover',
              }}
            />
          </div>
        </div>

        <div
          className={
            withOpacity ? (isHovered ? 'with_opacity' : 'no_opacity') : ''
          }
          style={{
            marginTop: withOpacity ? '4px' : '16px',
            // backgroundColor: 'white',
            height: '20px',
            borderRadius: '14px',
          }}>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: withOpacity ? '10px' : '14px',
              color: withOpacity ? 'white' : 'grey',
              borderRadius: '16px',
              paddingLeft: '4px',
              paddingRight: '4px',
              // backgroundColor: 'white',
            }}
            className="bigbutton__title">
            {title.split(' ')[0]}
          </p>
        </div>
      </div>
    </>
  );
};
