// Action Types

const CHANGE_STORE = 'CHANGE_STORE';
const REMOVE_CURRENT_STORE = 'REMOVE_CURRENT_STORE';
const SET_RELATED_STORES = 'SET_RELATED_STORES';
const SET_In_View = 'SET_In_View';
const SET_FAV_ADDRESS = 'SET_FAV_ADDRESS';
const SET_FILTERS = 'SET_FILTERS';
const SET_SAME_LOCATION_STORES = 'SET_SAME_LOCATION_STORES';
const SET_CLOSE_LOCATION_STORES = 'SET_CLOSE_LOCATION_STORES';
const RESET_STORE_STATE = 'RESET_STORE_STATE';

// Initial State
const initialState: any = {
  Store: null,
  relatedStores: null,
  inView: null,
  favAddress: null,
  filters: [],
  sameLocationStores: [],
  closeLocationStores: [],
};

// Reducer
export function SelectedStoreReducer(
  state = initialState,
  action: { type: any; payload: any },
) {
  switch (action.type) {
    case CHANGE_STORE:
      return {
        ...state,
        Store: action.payload.store,
      };

    case REMOVE_CURRENT_STORE:
      return {
        ...state,
        relatedStores: null,
        inView: null,
        Store: null,
      };
    case RESET_STORE_STATE:
      return {
        ...state,
        Store: null,
        relatedStores: null,
        inView: null,
        favAddress: null,
      };

    case SET_RELATED_STORES:
      return {
        ...state,
        relatedStores: action.payload.relatedStores,
      };

    case SET_In_View:
      return {
        ...state,
        inView: action.payload.inView,
      };

    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };
    case SET_FAV_ADDRESS:
      console.log('action.payload?.favAddres ', action.payload?.favAddres);

      return {
        ...state,
        favAddress: action.payload?.favAddres || null, // Default to null if payload is missing or undefined
      };

    case SET_SAME_LOCATION_STORES:
      return {
        ...state,
        sameLocationStores: action.payload.stores,
      };
    case SET_CLOSE_LOCATION_STORES:
      return {
        ...state,
        closeLocationStores: action.payload.strs,
      };
    default:
      return state;
  }
}

// Action Creators
export function change_store(store: any) {
  return { type: CHANGE_STORE, payload: { store } };
}

export function remove_current_store() {
  return { type: REMOVE_CURRENT_STORE };
}

export function setRelatedStores(relatedStores: Array<any>) {
  return { type: SET_RELATED_STORES, payload: { relatedStores } };
}

export function setInView(inView: any) {
  return { type: SET_In_View, payload: { inView } };
}
export function setFavAddress(favAddres: any) {
  return { type: SET_FAV_ADDRESS, payload: { favAddres } };
}
export function setFiltersMAp(filters: any) {
  return { type: SET_FILTERS, payload: { filters } };
}
export function setSameLocationStores(stores: any) {
  return { type: SET_SAME_LOCATION_STORES, payload: { stores } };
}
export function setCloseLocationStores(strs: any) {
  return { type: SET_CLOSE_LOCATION_STORES, payload: { strs } };
}
export function resetStoreState() {
  return { type: RESET_STORE_STATE };
}
