import React, { useState, useEffect } from 'react';
import { useTranslate } from '@pankod/refine-core';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  notification,
} from '@pankod/refine-antd';
import district from 'constants/district';
import { Address } from 'interfaces';
import axios from 'axios';
import UserHandler from 'util/UserHandler';
import { Alert } from 'antd';
import './styles.css';

interface IAddressModal {
  title: string;
  submitText?: string;
  visible: boolean;
  search?: boolean;
  address?: Address;
  onSubmit: (address: Address) => void;
  onCancel?: () => void;
}
type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const AddressModal: React.FC<IAddressModal> = ({
  title,
  visible,
  address,
  onSubmit,
  onCancel,
  submitText = 'Cerca indirizzo',
  search,
}) => {
  const t = useTranslate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [form] = Form.useForm<Address>();

  const openNotificationWithIcon = (type: NotificationType) => {
    notification[type]({
      message: t('addressPage.notification.title'),
      description: t('addressPage.notification.message'),
    });
  };

  useEffect(() => {
    form?.setFieldsValue({
      name: address?.name,
      district: address?.district,
      address: address?.address,
      civic_number: address?.civic_number,
      extra_info: address?.extra_info,
    });
  }, [address]);

  const getAddressLocation = async (formValues: Address) => {
    const postAddress = async (addressStr: string) => {
      const response = await axios
        .get(`${process.env.REACT_APP_API}/map/geocoder`, {
          params: {
            district: formValues.district,
            civic_number: formValues.civic_number,
            street: formValues.address,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${UserHandler.getInstance().token}`,
          },
        })
        .then(res => {
          return res.data?.data;
        });
      return response;
    };

    setDisabled(true);
    const geocode: any = await postAddress(
      `${formValues.district} ${formValues.address ?? ''} ${
        formValues.civic_number
      }`,
    );
    if (geocode?.civic_number === formValues.civic_number) {
      const newAddress = {
        name: formValues?.name,
        district: formValues?.district,
        address:
          geocode?.street ??
          formValues?.address ??
          `${formValues.district} ${formValues.address ?? ''} ${
            formValues.civic_number
          }`,
        civic_number: formValues?.civic_number,
        extra_info: formValues?.extra_info,
        city_code: geocode.cap,
        latitude: geocode.coordinates.lat,
        longitude: geocode.coordinates.lng,
      } as Address;
      onSubmit(newAddress);
    } else {
      openNotificationWithIcon('error');
    }
    setDisabled(false);
  };
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => {
        setDisabled(false);
        if (onCancel) onCancel();
      }}
      footer={false}>
      {address?.message && (
        <Alert
          message={address?.message}
          type={address?.messageType || 'info'}
          closable
        />
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={values => getAddressLocation(values)}
        initialValues={{
          name: address?.name,
          district: address?.district,
          address: address?.address,
          civic_number: address?.civic_number,
          extra_info: address?.extra_info,
        }}
        requiredMark={false}
        scrollToFirstError>
        {!search && (
          <Form.Item
            label={t('profile.editAddress.nameModal')}
            className="editAddress__formItem"
            name="name"
            rules={[
              {
                required: true,
                message: t('profile.editAddress.nameRequired'),
              },
            ]}>
            <Input
              type="text"
              size="large"
              placeholder={t('profile.editAddress.namePlaceholder')}
            />
          </Form.Item>
        )}
        <Form.Item
          label={t('profile.editAddress.district')}
          className="editAddress__formItem"
          name="district"
          rules={[
            {
              required: true,
              message: t('profile.editAddress.districtRequired'),
            },
          ]}>
          <Select>
            {district.map((elem: any) => {
              return (
                <Select.Option key={elem.id} value={`${elem.text}`}>
                  {/**${elem.text} ${elem.cap} */}
                  {elem.text}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('profile.editAddress.civicNumber')}
          className="editAddress__formItem"
          name="civic_number"
          rules={[
            {
              required: true,
              message: t('profile.editAddress.civicNumberRequired'),
            },
          ]}>
          <Input
            type="text"
            placeholder={t('profile.editAddress.civicNumberPlaceholder')}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label={t('profile.editAddress.address')}
          className="editAddress__formItem"
          name="address">
          <Input
            type="text"
            size="large"
            placeholder={t('profile.editAddress.addressPlaceholder')}
          />
        </Form.Item>
        {!search && (
          <Form.Item
            label={t('addressPage.note')}
            className="editAddress__formItem"
            name="extra_info">
            <Input
              type="text"
              size="large"
              placeholder={t('profile.editAddress.notePlaceholder')}
            />
          </Form.Item>
        )}
        <Button
          className={
            disabled
              ? 'editAddress__formSubmitDisabled'
              : 'editAddress__formSubmit'
          }
          type="primary"
          size="large"
          htmlType="submit"
          disabled={disabled}
          block>
          {t('profile.editAddress.confirmModal.confirmAddress') || submitText}
        </Button>
      </Form>
    </Modal>
  );
};
