import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import en from './locales/en/common.json';
import it from './locales/it/common.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init(
    {
      compatibilityJSON: 'v3',
      resources: {
        en: {
          translation: en,
        },
        it: {
          translation: it,
        },
      },
      ns: ['translation'],
      defaultNS: 'translation',
      fallbackLng: 'en',
      partialBundledLanguages: true, // allows some resources to be set on initialization while others can be loaded using a backend connector
      interpolation: {
        escapeValue: false, // No need to escape for react
      },
      backend: {
        loadPath: `${process.env.REACT_APP_SERVER}/languages/{{lng}}/{{ns}}.json`,
      },
      debug: true,
    },
    err => {
      if (err)
        return console.log('something went wrong loading languages', err);
    },
  );

export default i18n;
