import React, { useState } from 'react';
import { useTranslate } from '@pankod/refine-core';
import navBarIcons from '../../constants/navBarIcons';

import './styles.css';
import { Select } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const { Option } = Select;
export interface IRoundIconButton {
  type: 'avatar' | 'location' | 'back' | 'quitNavigation' | 'locationNav';
  onClick: () => void;
  styleCustom?: any;
}

export const RoundIconButton: React.FC<IRoundIconButton> = ({
  type,
  onClick,
  styleCustom,
}) => {
  const t = useTranslate();

  let imageUrl = '';
  let alt = '';
  switch (type) {
    case 'avatar':
      imageUrl = navBarIcons.avatar;
      alt = t('roundIconButton.avatar');

      break;

    case 'location':
      imageUrl = navBarIcons.location;
      alt = t('roundIconButton.location');

      break;
    case 'back':
      imageUrl = navBarIcons.back;
      alt = t('roundIconButton.back');

      break;
    case 'quitNavigation':
      imageUrl = navBarIcons.quitNavigation;
      alt = 'Quit navigation';
  }

  return (
    <>
      {/* {imageUrl === navBarIcons.quitNavigation ? (
        <img
          style={{ width: '100px' }}
          src={imageUrl}
          alt={alt}
          className="iconButton i"
          onClick={() => onClick()}
        />
      ) : ( */}
      <img
        style={styleCustom}
        src={imageUrl}
        alt={alt}
        className="iconButton"
        onClick={() => onClick()}
      />
    </>
  );
};
