export enum StoreSubcategoryEnum {
  Asiatico = 1,
  Burgers,
  Gastronomia,
  Mediterraneo,
  Sushi,
  Poke,
  Pizza,
  Carne,
  Dolciegelati,
  Cocktailkit,
  Birra,
  Vino,
  Liquoriedistillati,
  Cocktail,
  Softdrinks,
  Pescheria,
  Ortofrutta,
  Prodotti_caseari,
  Panificati,
  Aromieprofumi,
  Macelleria,
  Salumieaffettati,
  Artigianato,
  Bodycare,
  Fiori,
  Petcare,
  Homebeauty,
  Utilities,
  Deposit,
  Events,
  Pills,
  Fountain,
  Experience,
  Service,
  cibo,
  bevande,
  Botteghe,
  onDemand,
  smart_logistic,
}
