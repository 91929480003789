// Action Types
const TOGGLE_ACTV = 'TOGGLE_ACTV';
const CHANGE_ACTV_MODE = 'CHANGE_ACTV_MODE';
const CHANGE_MARKERS_NAV = 'CHANGE_MARKERS_NAV';
const CHANGE_MARKERS_NAV_REST = 'CHANGE_MARKERS_NAV_REST';

const UPDATE_TIME_TABLES = 'UPDATE_TIME_TABLES';
const CHANGE_SELECTED_STOP = 'CHANGE_SELECTED_STOP';
const LineDraw = 'LineDraw';
const REMOVE_ALL = 'REMOVE_ALL';

const FILTER = 'FILTER';
// Initial State
const initialState: any = {
  actvModeOn: false,
  markersNav: null,
  markersNavRest: null,

  timeTables: null,
  selectedStop: null,
  drawLine: null,
  filters: '',
};

// Reducer
export function actvReducer(
  state = initialState,
  action: { type: any; payload: any },
) {
  switch (action.type) {
    case TOGGLE_ACTV:
      return {
        ...state,
        actvModeOn: !state.actvModeOn,
      };

    case CHANGE_ACTV_MODE:
      return {
        ...state,
        actvModeOn: action.payload.mode,
      };
    case CHANGE_MARKERS_NAV:
      return {
        ...state,
        markersNav: action.payload.markers,
      };
    case CHANGE_MARKERS_NAV_REST:
      return {
        ...state,
        markersNavRest: action.payload.markers,
      };

    case UPDATE_TIME_TABLES:
      return {
        ...state,
        timeTables: action.payload.timeTables,
      };
    case CHANGE_SELECTED_STOP:
      return {
        ...state,
        selectedStop: action.payload.stop,
      };
    case LineDraw:
      return {
        ...state,
        drawLine: action.payload.line,
      };
    case FILTER:
      return {
        ...state,
        filters: action.payload.text,
      };
    case REMOVE_ALL:
      return {
        actvModeOn: false,
        markersNav: null,
        timeTables: null,
        selectedStop: null,
        drawLine: null,
      };
    default:
      return state;
  }
}

// Action Creators
export function toggleActv() {
  return { type: TOGGLE_ACTV };
}

export function changeActvMode(mode: boolean) {
  return { type: CHANGE_ACTV_MODE, payload: { mode } };
}

export function markersNavUpdate(markers: any) {
  return { type: CHANGE_MARKERS_NAV, payload: { markers } };
}
export function markersNavUpdateRest(markers: any) {
  return { type: CHANGE_MARKERS_NAV_REST, payload: { markers } };
}
export function updateTimeTables(timeTables: any) {
  return { type: UPDATE_TIME_TABLES, payload: { timeTables } };
}
export function changeStop(stop: any) {
  return { type: CHANGE_SELECTED_STOP, payload: { stop } };
}
export function drawLine(line: any) {
  return { type: LineDraw, payload: { line } };
}
export function updatefilter(text: any) {
  return { type: FILTER, payload: { text } };
}
export function removeActv() {
  return { type: REMOVE_ALL };
}
