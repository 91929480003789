import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslate } from '@pankod/refine-core';

import './styles.css';

export const Loading: React.FC = () => {
  const t = useTranslate();
  return (
    <div className="screen">
      <LoadingOutlined
        className="screen--spinner"
        style={{ fontSize: 18 }}
        spin
      />
      <h1 className="screen__title">{t('loading.title')}</h1>
    </div>
  );
};
