import { createReducer } from '@reduxjs/toolkit';
import { popAccessToken, pushAccessToken } from './accessToken.actions';

const accessTokenInitialState = '';

export const accessTokenReducer = createReducer(
  accessTokenInitialState,
  builder =>
    builder
      .addCase(popAccessToken, () => accessTokenInitialState)
      .addCase(pushAccessToken, (state, action) => action.payload),
);
